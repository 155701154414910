import { render, staticRenderFns } from "./NewEmployeeCard.vue?vue&type=template&id=119ed5de&scoped=true&"
import script from "./NewEmployeeCard.vue?vue&type=script&lang=js&"
export * from "./NewEmployeeCard.vue?vue&type=script&lang=js&"
import style0 from "./NewEmployeeCard.vue?vue&type=style&index=0&id=119ed5de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "119ed5de",
  null
  
)

export default component.exports