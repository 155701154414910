<template>
    <ReactionContainer
        v-slot="{changedTotalReactions, onReact, openReactionsModal}"
        class="footer"
        :content-type="contentType"
        :root-entity-uid="contentUid"
        :entity-uid="contentUid"
        :path="path"
        :selected-reaction-type="story.currentUsersReaction"
        :total-reactions="story.reactions"
        @react="$emit('react', $event)"
    >
        <ReactionGraph
            :reactions="changedTotalReactions"
            @open="openReactionsModal"
        />

        <ReactionButton
            mode="button"
            :init-selection="story.currentUsersReaction"
            :center-overlay="true"
            @react="onReact"
            @unreact="onReact(null)"
        />
    </ReactionContainer>
</template>

<script>
import { mapGetters } from "vuex";

import ReactionButton from "@web/components/reaction/ReactionButton";
import ReactionGraph from "@web/components/reaction/ReactionGraph";

import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import { CURRENT_USER } from "@/store/auth/getters";
import ReactionContainer from "@/views/story/ReactionContainer";

export default {
    name: "StoryFooter",
    components: { ReactionContainer, ReactionButton, ReactionGraph },
    props: {
        story: Object,
        selectedReactionType: { type: String, default: null },
    },
    data() {
        return {
            contentType: "story"
        };
    },
    computed: {
        ...mapGetters({
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
        path() {
            return this.story.creatorUid;
        },
        contentUid() {
            return this.story.uid;
        },
    },
};
</script>

<style lang="scss" scoped>
.footer {

    .react-button {
        left: 50%;
        transform: translate(-50%);
    }
}
</style>
