<template>
    <article
        v-resize:compact-view="150"
        class="story cover cursor-pointer depth-2"
        @click="$emit('click')"
    >
        <icon-bubble-overlay
            class="icon-bubble-overlay"
            :svg-content-string="mostRecentReactionIcon"
            :count="10"
            :bottom="-20"
            :top="10"
            :max-duration="2"
            :min-duration="1"
        />
        <div class="image-wrapper">
            <div
                class="image"
                :class="imageClass"
                :style="`background-image: url('${storyImageUrl}')`"
            >
                <user-loading-component :user-uid="story.creatorUid">
                    <template #default="{userLoading, user}">
                        <div v-fade-in.when="!userLoading">
                            <div class="avatar">
                                <img
                                    v-if="user"
                                    :src="user.photoURL"
                                    alt="user.displayName"
                                />
                            </div>
                            <div class="meta gradient-bottom">
                                <div
                                    v-if="user"
                                    v-resize:longname.gt="140"
                                    class="author"
                                >
                                    {{ user.displayName }}
                                </div>
                            </div>
                        </div>
                    </template>
                </user-loading-component>
            </div>
        </div>
    </article>
</template>

<script>
import { storyService } from "@web/services";
import { mapState } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import UserLoadingComponent from "@/components/user/UserLoadingComponent";
import { registerSnapshotListenerOnReactableEntity } from "@/lib/reactable-entity-snapshot-listener";
import {
    ReactableRootEntityType,
    ReactionType
} from "@backend/reaction/types";
import reactionIcons from "@web/components/reaction/reaction-icons";
import IconBubbleOverlay from "@/components/reaction/IconBubbleOverlay";

export default {
    name: "Story",
    components: { UserLoadingComponent, IconBubbleOverlay },
    props: {
        story: { type: Object, require: true }
    },
    data() {
        return {
            imageClass: "",
            storyImageUrl: "",
            mostRecentReactionState: undefined,
            mostRecentReaction: undefined,
            unsubscribeReactionsListener: () => {},
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
        mostRecentReactionIcon() {
            if (!this.mostRecentReaction) {
                return;
            }
            return reactionIcons[this.mostRecentReaction];
        }
    },
    mounted() {
        this.fetchImage();
        this.unsubscribeReactionsListener = registerSnapshotListenerOnReactableEntity(
            this.intranet.uid,
            ReactableRootEntityType.story,
            this.story.uid,
            this.story.creatorUid,
            this.startReactionAnimation,
        );
    },
    destroyed() {
        this.unsubscribeReactionsListener();
    },
    methods: {
        async fetchImage() {
            if (this.intranet && this.intranet.uid) {
                this.storyImageUrl = await storyService.fetchDownloadUrlForStoryImage(this.intranet.uid, this.story);
                // Uncomment to enable displaying landscape stories as downsized previews
                // this.setImageClass();
            }
        },
        startReactionAnimation(snapshot) {
            if (!snapshot.exists) {
                return;
            }
            const newReactionState = snapshot.data().reactions;
            if (this.mostRecentReactionState) {
                const diff = this._getReactionStateDiff(this.mostRecentReactionState, newReactionState);
                this.mostRecentReaction = this._getNewReaction(diff);
            }
            this.mostRecentReactionState = newReactionState;
        },
        _getReactionStateDiff(oldState, newState) {
            const diff = {};
            Object.keys(ReactionType).forEach(reactionType => {
                diff[reactionType] = newState[reactionType] - oldState[reactionType];
            });
            return diff;
        },
        _getNewReaction(reactionStateDiff) {
            for (const reactionType of Object.keys(reactionStateDiff)) {
                if (reactionStateDiff[reactionType] > 0) {
                    return reactionType;
                }
            }
        }
        // Uncomment to enable displaying landscape stories as downsized previews
        // setImageClass() {
        //     const image = new Image();
        //     image.src = this.storyImageUrl;
        //     image.onload = () => {
        //         this.imageClass = ((image.width / image.height) < 1) ? "portrait" : "landscape";
        //     };
        // }
    }
};
</script>

<style lang="scss" scoped>

    .aui-layout .story,
    :host-context(.aui-layout) .story {
        margin-bottom: 4rem;
    }

    .story {
        display: flex;
        animation-delay: var(--stagger);
        flex: 1 0 auto;
        border: none;
        height: 100%;
        overflow: hidden;
        border-radius: var(--border-radius);
        background-color: var(--lowest-contrast, #{$off-white});
        transition: transform 0.2s ease, box-shadow 0.2s ease-in-out;

        .icon-bubble-overlay {
            z-index: 2;
        }

        &:hover {
            transform: scale(1.02) translate3d(0, -4px, 0);
            box-shadow: $depth-3;
        }

        &.compact-view {
            .image-wrapper {
                .image {
                    .avatar {
                        width: 2.4rem;
                        height: 2.4rem;
                    }
                }
            }
        }

        .image-wrapper {
            position: relative;
            z-index: 1;
            width: 100%;

            .image {
                background: no-repeat center / cover;
                width: 100%;
                height: 100%;

                .avatar {
                    width: 3rem;
                    height: 3rem;
                    margin: 1rem;
                }

                .text {
                    color: $white;
                }

                .gradient-bottom {
                    background: linear-gradient(0deg, rgba($black, $opacity-eighty) 0%, rgba($black, 0) 100%);
                }

                .meta {
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    font-weight: 800;
                    font-size: 0.75rem;
                    line-height: 1.4;
                    opacity: 1;
                    color: $white;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding: 1rem;
                    width: 100%;
                }
            }
        }
    }

</style>
