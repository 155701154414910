<template>
    <div class="social-notification-list">
        <transition-group name="fade">
            <div
                v-for="(notification, index) in notifications"
                :key="notification.created.seconds.toString() + notification.created.nanoseconds.toString() + notification.activity.creatorUid + notification.activity.meta.socialNotificationType"
                v-tooltip.bottom="!isInteractable(notification) ? '' : $t('click_to_open')"
                class="card flex"
                :class="{
                    'mbottom-xsmall': index !== notifications.length - 1,
                    'clickable': isInteractable(notification),
                }"
                @click="maybeStartInteraction(notification)"
            >
                <router-link
                    :to="{ name: 'user-profile', params: { userUid: notification.activity.creatorUid }}"
                    @click.stop
                >
                    <div class="avatar">
                        <img :src="notification.creator.photoURL" />
                    </div>
                </router-link>
                <div class="flex flex-column flex-grow-1 mleft-xsmall">
                    <span class="meta">
                        <router-link
                            :to="{ name: 'user-profile', params: { userUid: notification.activity.creatorUid }}"
                            @click.stop
                        >
                            {{ notification.creator.displayName }}
                        </router-link>
                    </span>
                    <strong>
                        <span class="left-emoji">{{ getEmoji(notification.activity.meta.socialNotificationType) }}</span>
                        {{ getText(notification.activity.meta.socialNotificationType) }}
                        <span>{{ getEmoji(notification.activity.meta.socialNotificationType) }}</span>
                    </strong>
                </div>
                <div>
                    <icon
                        v-tooltip.bottom="$t('hide')"
                        name="x"
                        class="clickable"
                        @click.native.stop="markAsReadCallback(notification.id)"
                    />
                </div>
            </div>
        </transition-group>
        <infinite-scroll-spinner
            :loading="loading"
            @load-more="$emit('load-more')"
        />
    </div>
</template>

<script>
import { SocialNotificationType } from "@backend/common/notification/activity-types";
import InfiniteScrollSpinner from "@/components/InfiniteScrollSpinner";
import {
    SOCIAL_NOTIFICATION_TYPE_EMOJI_MAP,
    SOCIAL_NOTIFICATION_INTERACTION_MAP
} from "@/components/social-notifications/config";
import { analytics } from "@/analytics";
import { getGlobalConfiguration } from "@/global-config";

export default {
    name: "SocialNotificationList",
    components: { InfiniteScrollSpinner },
    props: {
        /**
         * @type {SocialNotification[]}
         */
        notifications: {
            type: Array,
            required: true,
        },
        /**
         * Callback which is called when a notification is marked as read
         *
         * @type {(notificationId: string) => void}
         */
        markAsReadCallback: {
            type: Function,
            required: true,
        },
        /**
         * Loading state which is used for the infinite spinner
         */
        loading: Boolean,
    },
    methods: {
        getEmoji(notificationType) {
            return SOCIAL_NOTIFICATION_TYPE_EMOJI_MAP[notificationType];
        },
        getText(notificationType) {
            if (notificationType === SocialNotificationType.newEmployee) {
                return this.$t("social_notification_text_new_employee");
            }
            if (notificationType === SocialNotificationType.heyFive) {
                return this.$t("social_notification_text_hey_five");
            }
            if (notificationType === SocialNotificationType.birthday) {
                return this.$t("social_notification_text_birthday");
            }
            if (notificationType === SocialNotificationType.anniversary) {
                return this.$t("social_notification_text_anniversary");
            }
            throw new Error(`Unknown notification type: ${notificationType}`);
        },
        isInteractable(notification) {
            return SOCIAL_NOTIFICATION_INTERACTION_MAP[notification.activity.meta.socialNotificationType] !== undefined;
        },
        maybeStartInteraction(notification) {
            if (this.isInteractable(notification)) {
                this.$emit("start-interaction", notification);
                analytics.log(getGlobalConfiguration().analytics_event_name_social_notification_interaction_started, {
                    socialNotificationType: notification.activity.meta.socialNotificationType,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.social-notification-list {
    max-height: 19rem;
    overflow: auto;

    .card {
        border: none;
        background: var(--lowest-contrast);
        padding: .7rem $spacing-small;

        .avatar {
            height: 2.5rem;
            width: 2.5rem;
            flex-shrink: 0;
        }

        .meta {
            color: var(--highest-contrast);
        }

        .left-emoji {
            margin-right: .1rem;
        }

        &.clickable {
            transition: background .2s linear;

            &:hover {
                background: var(--low-contrast);
            }
        }
    }
}
</style>

<docs>
### Build a Social Inbox using SocialNotificationList
```vue
<template>
    <div>
        <div>
            <social-notification-list
                :notifications="notifications"
                :mark-as-read-callback="removeNotification"
                :loading="loading"
                @load-more="loadMore"
            />
        </div>
        <button class="mtop-large" @click="addNotification">Add Notification</button>
    </div>
</template>
<script>
    import { SocialNotificationType } from "@backend/common/notification/activity-types";

    export default {
        data() {
            return {
                idCounter: 1,
                notifications: [],
                loading: false,
            };
        },
        methods: {
            addNotification() {
                this.notifications.push(this.generateNotification());
            },
            generateNotification() {
                const id = this.idCounter++;
                const types = Object.values(SocialNotificationType);
                return {
                    id: id,
                    activity: {
                        meta: {
                            socialNotificationType: types[Math.floor(Math.random()*types.length)],
                        },
                        creatorUId: "123",
                    },
                    creator: {
                        photoURL: `https://i.pravatar.cc/150?id=${id}`,
                        displayName: `User ${id}`,
                    },
                    created: {
                        nanoseconds: Math.random(),
                        seconds: Math.random(),
                    }
                }
            },
            removeNotification(notificationId) {
                this.notifications = this.notifications.filter(notification => notification.id !== notificationId);
            },
            loadMore() {
                this.loading = true;
                setTimeout(() => {
                    for (let i = 0; i < 3; i++) {
                        this.addNotification();
                    }
                    this.loading = false;
                }, 3000);
            },
        },
        mounted() {
            this.addNotification();
        },
    };
</script>
```
</docs>
