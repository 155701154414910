import { render, staticRenderFns } from "./UserStorySlides.vue?vue&type=template&id=37fe0f68&scoped=true&"
import script from "./UserStorySlides.vue?vue&type=script&lang=js&"
export * from "./UserStorySlides.vue?vue&type=script&lang=js&"
import style0 from "./UserStorySlides.vue?vue&type=style&index=0&id=37fe0f68&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37fe0f68",
  null
  
)

export default component.exports