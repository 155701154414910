<template>
    <LoadingWrapper
        :is-loading="loading"
        :class="{'loading-spinner-stories': loading}"
    >
        <div v-if="!loading && stories.length > 0">
            <vueper-slides
                ref="teaserSlides"
                class="no-shadow"
                slide-multiple
                :disable-arrows-on-edges="configuration.disableArrowsOnEdges"
                :bullets-outside="configuration.bulletsOutside"
                :arrows-outside="configuration.arrowsOutside"
                :arrows="configuration.arrows"
                :visible-slides="configuration.visibleSlides"
                :slide-ratio="configuration.slideRatio"
                :infinite="configuration.infinite"
                :progress="configuration.progress"
                :autoplay="configuration.autoplay"
                :gap="configuration.gap"
                :fractions="configuration.fractions"
                :touchable="configuration.touchable"
                :bullets="configuration.bullets"
                :breakpoints="configuration.breakpoints"
                @slide="onSlide"
                @ready="onReady"
            >
                <vueper-slide
                    v-for="story in stories"
                    :key="story.uid"
                >
                    <template v-slot:content>
                        <story
                            :story="story"
                            @click="openDetailView(story.creatorUid)"
                        />
                    </template>
                </vueper-slide>

                <template #arrow-left>
                    <div class="button story-button light prev depth-2 only-icon">
                        <icon
                            name="arrow-left"
                            color="var(--foreground)"
                            size="small"
                        />
                    </div>
                </template>

                <template #arrow-right>
                    <div
                        v-if="hasNextPage"
                        class="button story-button light next depth-2 only-icon"
                    >
                        <icon
                            name="arrow-right"
                            color="var(--foreground)"
                            size="small"
                        />
                    </div>
                </template>
            </vueper-slides>
        </div>
    </LoadingWrapper>
</template>

<script>
import Story from "@web/views/story/Story.vue";
import Vue from "vue";
import {
    VueperSlide,
    VueperSlides
} from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import {
    mapActions,
    mapGetters
} from "vuex";
import { STORY_MODULE_NAME } from "@web/store/story/story";
import {
    GET_STORIES,
    HAS_MORE
} from "@web/store/story/getters";
import { FETCH_STORIES } from "@web/store/story/actions";
import Icon from "@/components/Icon";

export default Vue.extend({
    name: "StoryTeaserSlides",
    components: {
        Icon,
        Story,
        VueperSlides,
        VueperSlide,
    },
    data() {
        return {
            loading: false,
            initialized: false,
            currentSlideIndex: 0,
            viewedSlides: 6,
            configuration: {
                bullets: false,
                progress: false,
                bulletsOutside: false,
                arrowsOutside: false,
                disableArrowsOnEdges: true,
                touchable: false,
                autoplay: false,
                arrows: true,
                infinite: false,
                fractions: false,
                gap: 3,
                key: "",
                visibleSlides: 6,
                slideRatio: 0.2,
                breakpoints: {
                    480: { visibleSlides: 2, slideRatio: 0.6 },
                    640: { visibleSlides: 3, slideRatio: 0.4 },
                    900: { visibleSlides: 4, slideRatio: 0.3 },
                    960: { visibleSlides: 2, slideRatio: 0.6 },
                    1280: { visibleSlides: 3, slideRatio: 0.4 },
                    1440: { visibleSlides: 4, slideRatio: 0.3 },
                    1904: { visibleSlides: 5, slideRatio: 0.25 },
                },
                resizeEvent: null,
            }
        };
    },
    computed: {
        ...mapGetters({
            stories: STORY_MODULE_NAME + GET_STORIES,
            hasMore: STORY_MODULE_NAME + HAS_MORE
        }),
        lastPageStartIndex() {
            return this.stories.length - this.currentVisibleSlides;
        },
        onLastPage() {
            return this.currentSlideIndex >= this.lastPageStartIndex;
        },
        hasNextPage() {
            return this.currentSlideIndex < this.lastPageStartIndex;
        },
        currentVisibleSlides() {
            const slides = this.$refs.teaserSlides;
            if (!slides) {
                return this.configuration.visibleSlides;
            }
            const bp = slides.breakpointsData.current;
            if (!this.configuration.breakpoints.hasOwnProperty(bp)) {
                return this.configuration.visibleSlides;
            }
            return this.configuration.breakpoints[bp].visibleSlides;
        }
    },
    methods: {
        ...mapActions({
            fetchStories: STORY_MODULE_NAME + FETCH_STORIES,
        }),
        async openDetailView(creatorUid) {
            this.$emit("onStoryClick", creatorUid);
        },
        async onSlide(slideEvent) {
            this.currentSlideIndex = slideEvent.currentSlide.index;
            if (this.onLastPage && this.hasMore) {
                await this.loadMore(this.configuration.visibleSlides);
            }
        },
        onReady() {
            // Trigger init manually to prevent layout issues (HALO-6505)
            if (!this.initialized) {
                this.$refs.teaserSlides.init();
                this.initialized = true;
            }
        },
        async loadMore(count) {
            await this.fetchStories({ limit: count || this.configuration.visibleSlides });
        },
        async init() {
            try {
                this.loading = true;
                await this.fetchStories({ limit: this.configuration.visibleSlides, initial: true });
            } catch (e) {
                console.error("error loading stories", e);
            } finally {
                this.loading = false;
            }
        }
    },
    created() {
        this.init();
    }
});
</script>

<style lang="scss" scoped>

.loading-spinner-stories {
    height: 175px;
}

.system-message-container {
    ::v-deep {
        .image-container {
            padding: 0;
            svg {
                height: 175px;
            }
        }

    }
}

::v-deep {
    .vueperslides{
        overflow-x: hidden;
        padding: 2rem 0;
    }

    .vueperslides__track,
    .vueperslides__parallax-wrapper {
        overflow: visible;
    }

    .vueperslides__arrow svg {
        padding: 0;
        width: auto;
    }
}

</style>
