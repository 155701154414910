<template>
    <div
        class="calendar-event-row"
        @click="openUrl"
    >
        <div class="event-time">
            <span>{{ time }}</span>
        </div>
        <div class="event-information">
            <div class="event-title">
                <strong>{{ title }}</strong>
                <Icon
                    class="icon small"
                    :name="'external-link'"
                    :color="'rgba(0,0,0,0.3)'"
                />
            </div>
            <span
                v-if="subtitle"
                class="event-subtitle"
            >{{ subtitle }}</span>
        </div>
    </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    name: "CalendarEventRow",
    components: {},
    props: {
        title: {
            type: String,
        },

        subtitle: {
            type: String,
        },

        time: {
            type: String,
        },

        url: {
            type: String,
        },
    },
    data() {
        return {
            result: {
                items: []
            },
            loading: true,
        };
    },
    computed: {},
    methods: {
        openUrl() {
            window.open(this.url);
        }
    }
});
</script>

<style lang="scss" scoped>
    .calendar-event-row {
        display: flex;
        flex-direction: row;
        cursor: pointer;

        &:hover {
            .event-information .event-title .icon {
                opacity: 1;
            }
        }

        .event-time {
            font-family: 'Lucida Console', Monaco, monospace;
            font-size: 15px;
            font-weight: bold;
            color: var(--primary);
            padding-right: 2.2rem;
        }

        .event-information {
            display: flex;
            flex-direction: column;
            position: relative;
            max-width: calc(100% - 65px);

            .event-title {
                max-width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                overflow-wrap: anywhere;
                overflow: hidden;
            }

            .icon {
                margin-left: 0.5rem;
                opacity: 0;
                transition: opacity .2s ease;
            }

            strong {
                width: auto;
            }

            .event-subtitle {
                color: var(--sidebar-color);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 12px;
            }
        }
    }
</style>
