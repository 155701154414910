import { Timestamp } from "@backend/entity/types";

export enum HappinessUserSubmissionInFireStoreFieldKeys {
    value = "value",
    type = "type",
    submissionDate = "submissionDate"
}

export enum HappinessSurveyTypes {
    team = "team",
    company = "company"
}

export enum HappinessSurveySelectionValues {
    frustrated = "frustrated",
    unhappy = "unhappy",
    neutral = "neutral",
    happy = "happy",
    cheerful = "cheerful"
}

export interface HappinessUserSubmission {
    [HappinessUserSubmissionInFireStoreFieldKeys.value]: HappinessSurveySelectionValues;
    [HappinessUserSubmissionInFireStoreFieldKeys.type]: HappinessSurveyTypes;
}

export interface HappinessUserSubmissionInFireStore extends HappinessUserSubmission {
    [HappinessUserSubmissionInFireStoreFieldKeys.submissionDate]: Timestamp
}

export type HappinessCountBySurveySelection = {
    [key in HappinessSurveySelectionValues]: number;
}

export interface HappinessDailyReportInFireStore {
    count: HappinessCountBySurveySelection;
    reportDate: Timestamp;
    includedDays: number;
}

export interface HappinessDailyReport {
    count: HappinessCountBySurveySelection;
    reportDate: Timestamp;
    includedDays: number;
    average: number;
    median: number;
    submissions: number;
}

export interface UserHasPermissionResult {
    hasPermission: boolean;
    daysUntilNextVote: number;
}

export const numericValueForHappinessSelectionValues = {
    frustrated: 1,
    unhappy: 2,
    neutral: 3,
    happy: 4,
    cheerful: 5
};

export function emptyHappinessCountBySurveySelection(): HappinessCountBySurveySelection {
    return {
        frustrated: 0,
        unhappy: 0,
        neutral: 0,
        happy: 0,
        cheerful: 0
    };
}

export const happinessTooEarlySurveySubmissionError = "HappinessTooEarlySurveySubmissionError";
