var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.processedContent)?_c('svg',{ref:"animationCanvas",staticClass:"animation-canvas",attrs:{"xmlns":"http://www.w3.org/2000/svg"}},[_c('defs',[(_vm.processedContent)?_c('symbol',{attrs:{"id":_vm.elementUid}},[_c('g',{domProps:{"innerHTML":_vm._s(_vm.processedContent)}})]):_vm._e()]),_vm._l((_vm.instances),function(instance,index){return _c('use',{key:index,staticClass:"animation-instance",style:({
            '--pos-x': instance.posX,
            '--pos-y-start': instance.posYStart,
            '--pos-y-end': instance.posYEnd,
            '--scale': instance.scale,
            '--duration': instance.duration,
            '--delay': instance.delay,
            '--rotation-start': instance.rotationStart,
            '--rotation-end': instance.rotationEnd,
        }),attrs:{"href":("#" + _vm.elementUid)}})})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }