<template>
    <div>
        <h3>
            <span>{{ $t("watched_issues") }}</span>
            <a
                :href="instanceUrl + '/jira'"
                target="_blank"
            >
                <div
                    class="vendor-icon"
                    v-html="require(`@web/assets/vendors/jira.svg`)"
                ></div>
            </a>
        </h3>
        <LoadingWrapper :is-loading="loading">
            <ActivityGroup
                v-if="todaysIssues.length > 0"
                :name="$t('today')"
            >
                <Activity
                    v-for="entity in todaysIssues"
                    :key="entity.id"
                    :title="entity.fields.summary"
                    :url="instanceUrl + '/browse/' + entity.key"
                >
                    <template v-slot:icon>
                        <div class="icon">
                            <img
                                class="icon-url"
                                :src="entity.fields.issuetype.iconUrl"
                            />
                        </div>
                    </template>
                    <template v-slot:meta>
                        <div>
                            <span class="reporter-name">{{ entity.key }}</span> {{ beautifyDate(entity.fields.updated) }}
                        </div>
                    </template>
                </Activity>
            </ActivityGroup>
            <ActivityGroup
                v-if="yesterdaysIssues.length > 0"
                :name="$t('yesterday')"
            >
                <Activity
                    v-for="entity in yesterdaysIssues"
                    :key="entity.id"
                    :title="entity.fields.summary"
                    :url="instanceUrl + '/browse/' + entity.key"
                >
                    <template v-slot:icon>
                        <div class="icon">
                            <img
                                class="icon-url"
                                :src="entity.fields.issuetype.iconUrl"
                            />
                        </div>
                    </template>
                    <template v-slot:meta>
                        <div>
                            <span class="reporter-name">{{ entity.key }}</span> {{ beautifyDate(entity.fields.updated) }}
                        </div>
                    </template>
                </Activity>
            </ActivityGroup>
            <ActivityGroup
                v-if="olderIssues.length > 0"
                :name="titleForOlderIssues"
            >
                <Activity
                    v-for="entity in olderIssues"
                    :key="entity.id"
                    :title="entity.fields.summary"
                    :url="instanceUrl + '/browse/' + entity.key"
                >
                    <template v-slot:icon>
                        <div class="icon">
                            <img
                                class="icon-url"
                                :src="entity.fields.issuetype.iconUrl"
                            />
                        </div>
                    </template>
                    <template v-slot:meta>
                        <div>
                            <span class="reporter-name">{{ entity.key }}</span> {{ beautifyDate(entity.fields.updated) }}
                        </div>
                    </template>
                </Activity>
            </ActivityGroup>
        </LoadingWrapper>
    </div>
</template>

<script>
import Vue from "vue";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { mapState } from "vuex";
import { atlassianContentService } from "@web/services";
import ActivityGroup from "@web/views/atlassian/ActivityGroup";
import Activity from "@web/views/atlassian/Activity";
import dayjs from "dayjs";
import { friendlyFormatedAtlassianDate } from "@/lib/time-utils";

export default Vue.extend({
    name: "JiraActivity",
    components: { ActivityGroup, Activity },
    data() {
        return {
            issues: [],
            todaysIssues: [],
            yesterdaysIssues: [],
            olderIssues: [],
            loading: true,
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
        atlassianFeatureConfig() {
            return this.intranet.flags["atlassian-3lo"];
        },
        instanceUrl() {
            return this.atlassianFeatureConfig.config.instanceUrl;
        },
        titleForOlderIssues() {
            return this.todaysIssues.length === 0 && this.yesterdaysIssues.length === 0 ? "" : this.$t("older");
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        async fetch() {
            this.issues = (await atlassianContentService.fetchTicketsCurrentUserIsWatching(this.intranet.uid)).issues;
            const dateFormatForGrouping = "DD/MM/YYYY";
            const today = dayjs().format(dateFormatForGrouping);
            const yesterday = dayjs().subtract(1, "day").format(dateFormatForGrouping);
            this.issues.forEach(issue => {
                const updatedDateForIssue = dayjs(issue.fields.updated).format(dateFormatForGrouping);
                if (updatedDateForIssue === today) {
                    this.todaysIssues.push(issue);
                } else if (updatedDateForIssue === yesterday) {
                    this.yesterdaysIssues.push(issue);
                } else {
                    this.olderIssues.push(issue);
                }
            });
            this.loading = false;
        },
        beautifyDate(date) {
            return friendlyFormatedAtlassianDate(dayjs(date), "DD.MM.YYYY HH:mm");
        }
    },
});
</script>

<style lang="scss" scoped>
    .card {
        padding: 2rem;
    }

    .vendor-icon {
        width: 24px;
    }

    .icon {
        .icon-url {
            width: 100%;
            height: 100%;
        }
    }

    .meta {
        .reporter-name {
            color: var(--foreground, #{$black});
            font-weight: bold;
        }
    }

    h3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>
