<template>
    <div
        class="happiness-icon zoom-on-hover-trigger cursor-pointer"
        :style="containerStyle"
        @click="updateValue()"
    >
        <div
            :class="['icon-svg', 'zoom-small', additionalClasses]"
            :style="iconStyle"
            v-html="buildUrl()"
        ></div>
        <span
            v-if="showLabel"
            :class="['happiness-icon-label', additionalClasses]"

            :style="labelStyle"
        >
            {{ label }}
        </span>
    </div>
</template>

<script>
import { HappinessSurveySelectionValues } from "@backend/happiness/types";

const containerToIconRatio = 1.2;
const fontSizeToIconRatio = 0.25;
const maxFontSize = 16;
const minimumDisplayedFontSize = 12;

export default {
    name: "HappinessSelect",
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            type: String,
        },
        size: {
            type: Number,
            default: 32
        }
    },
    computed: {
        additionalClasses() {
            if (!this.value) {
                return "";
            }
            if (this.value === this.name) {
                return "selected";
            }
            return "unselected";
        },
        containerStyle() {
            return {
                width: `${Math.round(this.size * containerToIconRatio)}px`
            };
        },
        fontSize() {
            return Math.min(Math.round(this.size * fontSizeToIconRatio), maxFontSize);
        },
        showLabel() {
            return this.fontSize >= minimumDisplayedFontSize;
        },
        labelStyle() {
            return {
                fontSize: `${this.fontSize}px`
            };
        },
        iconStyle() {
            return {
                width: `${this.size}px`,
                height: `${this.size}px`,
            };
        },
        iconUrl() {
            return require(`@web/assets/happiness/${this.name}.svg`);
        },
        label() {
            if (this.name === HappinessSurveySelectionValues.frustrated) {
                return this.$t("happiness_selection_frustrated");
            }
            if (this.name === HappinessSurveySelectionValues.unhappy) {
                return this.$t("happiness_selection_unhappy");
            }
            if (this.name === HappinessSurveySelectionValues.neutral) {
                return this.$t("happiness_selection_neutral");
            }
            if (this.name === HappinessSurveySelectionValues.happy) {
                return this.$t("happiness_selection_happy");
            }
            if (this.name === HappinessSurveySelectionValues.cheerful) {
                return this.$t("happiness_selection_cheerful");
            }
            return this.$t("unknown");
        }
    },
    methods: {
        updateValue() {
            this.$emit("input", this.name);
        },
        buildUrl() {
            return require(`@web/assets/happiness/${this.name}.svg`);
        },
    }
};
</script>

<style lang="scss">
    .happiness-icon {
        font-size: 0.5rem;
        text-align: center;
        transition: color 0.2s ease-out;
        --icon-size: 32px;

        .icon-svg {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        .selected {
            color: var(--primary);
            --icon-color: var(--primary);
        }

        .unselected {
            color: var(--low-contrast, #{$light-grey});
            --icon-color: var(--low-contrast, #{$light-grey});
        }
    }

</style>

<docs>
Simple example:
```vue
<HappinessSelect name="neutral"/>
```

With a size:
```vue
<template>
    <div>
        <div class="flex flex-space-between">
            <HappinessSelect name="frustrated" :size="size" v-model="value"/>
            <HappinessSelect name="unhappy" :size="size" v-model="value"/>
            <HappinessSelect name="neutral" :size="size" v-model="value"/>
            <HappinessSelect name="happy" :size="size" v-model="value"/>
            <HappinessSelect name="cheerful" :size="size" v-model="value"/>
        </div>
        <div>
            <h2>Params</h2>
            <p>
                <input type="range" min="32" max="64" v-model="sizeInput"/>
                size: {{ size }}
            </p>
            <p>
                <button @click="value = null">Reset</button>
                value: {{ value }}
            </p>
        </div>
    </div>

</template>
<script>
    export default {
        data() {
            return {
                sizeInput: 48,
                value: null,
            };
        },
        computed: {
            size() {
                return parseInt(this.sizeInput);
            }
        }
    };
</script>
```
</docs>
