<template>
    <social-happening-card
        :happening="happening"
        :variant="variant"
        :button-text="$t('social_happenings_interaction_congratulate')"
        :social-notification-type="socialNotificationType"
        @interacted="$emit('interacted')"
    >
        <h3>{{ $t("social_happenings_anniversary_title") }}</h3>
        <p v-html="text" ></p>
    </social-happening-card>
</template>

<script>
import SocialHappeningCard from "@/components/social-happenings/SocialHappeningCard";
import escape from "lodash/escape";
import { dayjsFromDateYMD } from "@/lib/time-utils";
import { SocialNotificationType } from "@backend/common/notification/activity-types";

export default {
    name: "AnniversaryCard",
    components: { SocialHappeningCard },
    props: {
        /**
         * @type {SocialHappening}
         */
        happening: {
            type: Object,
            required: true,
        },
        /**
         * @values "primary", "secondary"
         */
        variant: {
            type: String,
            default: "primary",
        },
    },
    data() {
        return {
            socialNotificationType: SocialNotificationType.anniversary,
        };
    },
    computed: {
        text() {
            const years = this.happening.date.year() - dayjsFromDateYMD(this.happening.user.hiringDate).year();
            return this.$t(
                "social_happenings_anniversary_text", [
                    `<strong>${escape(this.happening.user.displayName)}</strong>`,
                    `<strong>${this.$t("years", [years])}</strong>`,
                ],
            );
        },
    }
};
</script>

<style lang="scss" scoped>
::v-deep {
    .emoji {
        animation-name: wiggle !important;
        animation-direction: alternate !important;

        &.main-emoji {
            animation-duration: .5s !important;
        }

        &.accent-emoji {
            animation-duration: .25s !important;
        }
    }
}
</style>
