<template>
    <div>
        <h3>
            <span>{{ $t("recent_updates") }}</span>
            <a
                :href="instanceUrl + '/wiki'"
                target="_blank"
            >
                <div
                    class="vendor-icon"
                    v-html="require(`@web/assets/vendors/confluence.svg`)"
                ></div>
            </a>
        </h3>
        <LoadingWrapper :is-loading="loading">
            <ActivityGroup
                v-for="profile in uniqueAtlassianProfiles"
                :key="profile.accountId"
                :name="profile.publicName"
                :img-url="instanceUrl + profile.profilePicture.path"
            >
                <Activity
                    v-for="entity in results"
                    v-if="entity.content.history.lastUpdated.by.accountId === profile.accountId"
                    :key="entity.content.id"
                    :title="entity.title"
                    :meta="beautifyDate(entity.content.history.lastUpdated.when)"
                    :type="entity.content.type"
                    :url="instanceUrl + '/wiki' + entity.url"
                />
            </ActivityGroup>
        </LoadingWrapper>
    </div>
</template>

<script>
import Vue from "vue";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { mapState } from "vuex";
import { atlassianContentService } from "@web/services";
import ActivityGroup from "@web/views/atlassian/ActivityGroup";
import Activity from "@web/views/atlassian/Activity";
import dayjs from "dayjs";
import { friendlyFormatedAtlassianDate } from "@/lib/time-utils";

export default Vue.extend({
    name: "ConfluenceActivity",
    components: { ActivityGroup, Activity },
    data() {
        return {
            results: [],
            loading: true,
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
        atlassianFeatureConfig() {
            return this.intranet.flags["atlassian-3lo"];
        },
        instanceUrl() {
            return this.atlassianFeatureConfig.config.instanceUrl;
        },
        uniqueAtlassianProfiles() {
            return this.results
                .map(r => r.content.history.lastUpdated.by)
                .filter((p, index, pArr) => pArr.findIndex(p2 => p2.accountId === p.accountId) === index);
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        async fetch() {
            this.results = (await atlassianContentService.fetchRecentConfluencePagesAndBlogposts(this.intranet.uid)).results;
            this.loading = false;
        },
        beautifyDate(date) {
            return friendlyFormatedAtlassianDate(dayjs(date), "DD.MM.YYYY HH:mm");
        }
    },
});
</script>

<style lang="scss" scoped>
    .card {
        padding: 2rem;
    }

    .vendor-icon {
        width: 24px;
    }

    h3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>
