<template>
    <Modal
        name="how-to-create-stories-modal"
        hide-footer
        :title="$t('create-story-hint')"
        @before-open="beforeOpenEvent"
        @before-close="beforeCloseEvent"
    >
        <div class="wrapper">
            <div class="desc">
                {{ $t("create-story-hint-desc") }}
            </div>
            <div class="col-wrapper">
                <div class="badge-wrapper">
                    <img
                        class="qr-code"
                        src="../../assets/stories/qr-lpc-ios.png"
                    />
                    <a
                        target="_blank"
                        href="https://seibert.biz/linchpincloudios"
                    >
                        <div
                            class="badge"
                            v-html="require('@web/assets/vendors/app-store-badge.svg')"
                        ></div>
                    </a>
                </div>
                <div class="badge-wrapper">
                    <img
                        class="qr-code"
                        src="../../assets/stories/qr-lpc-android.png"
                    />
                    <a
                        target="_blank"
                        href="https://seibert.biz/linchpincloudandroid"
                    >
                        <div
                            class="badge"
                            v-html="require('@web/assets/vendors/google-play-badge.svg')"
                        ></div>
                    </a>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import Vue from "vue";
import Modal from "@web/components/modals/Modal";

export default Vue.extend({
    name: "HowToCreateStoriesModal",
    components: { Modal },
    data() {
        return {
            open: false,
            routeBeforeOpen: null,
            nativePopstateEventListener: null,
        };
    },
    methods: {
        beforeOpenEvent(event) {
            this.open = true;
            this.routeBeforeOpen = this.$router.currentRoute.path;
        },
        beforeCloseEvent() {
            this.open = false;
        },
    },
});
</script>

<style lang="scss" scoped>

    .col-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        background-color: var(--lowest-contrast, #{$off-white});
        border-radius: var(--border-radius);

        .badge-wrapper {
            padding: 2rem;
            width: calc(50% - 1rem);
            display: flex;
            flex-direction: column;

            .badge {
                display: flex;
                justify-content: center;
                height: 40px;
            }

            .qr-code {
                display: inline-block;
                text-align: center;
                border-radius: 10px;
                overflow: hidden;
                border: 3px solid var(--foreground, #{$black});
                width: 128px;
                height: 128px;
                margin: 0 auto 1rem;
            }
        }
    }

</style>
