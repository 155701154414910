<template>
    <transition
        name="fade"
        appear
    >
        <div
            v-if="shouldShowIntegrationHint"
            class="no-integrations-hint"
            :class="{'hiding': disablingIntegrationHint}"
        >
            <div class="hide-integrations-trigger">
                <app-button
                    v-if="!disablingIntegrationHint"
                    class=""
                    type="link"
                    @click="disableIntegrationHint"
                >
                    {{ $t("hide") }}
                </app-button>
                <Spinner v-if="disablingIntegrationHint" />
            </div>
            <div class="no-integrations-hint-content">
                <div class="vendor-icons">
                    <div
                        class="vendor-icon"
                        v-html="require(`@web/assets/vendors/atlassian-white-icon.svg`)"
                    ></div>
                    <div
                        class="vendor-icon"
                        v-html="require(`@web/assets/vendors/google-white-icon.svg`)"
                    ></div>
                </div>
                <div class="text centered">
                    <h3>{{ $t('no_integrations_setup_yet_title') }}</h3>
                    <p>{{ $t('no_integrations_setup_yet_hint') }}</p>
                    <router-link :to="{ name: 'integrations' }">
                        <app-button icon="settings">
                            {{ $t("no_integrations_setup_trigger") }}
                        </app-button>
                    </router-link>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import {
    mapActions,
    mapGetters,
    mapState
} from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { RELOAD_CURRENT_INTRANET } from "@web/store/intranet/actions";
import { cloudRunUrl } from "@web/cloud-run-url";
import Spinner from "@web/components/Spinner";
import { IS_ADMIN } from "@web/store/intranet/getters";

export default {
    name: "NoIntegrationsHint",
    components: {
        Spinner,
    },
    data() {
        return {
            disablingIntegrationHint: false,
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
        ...mapGetters({ currentUserIsAdmin: INTRANET_MODULE_NAME + IS_ADMIN }),
        shouldShowIntegrationHint() {
            if (!this.currentUserIsAdmin) {
                return false;
            }
            return this.intranet.flags["integration-hint"].enabled && !this.atlassianFeatureEnabled && !this.googleCalendarFeatureEnabled;
        },
        atlassianFeatureEnabled() {
            return this.intranet.flags["atlassian-3lo"].enabled;
        },
        googleCalendarFeatureEnabled() {
            return this.intranet.flags["google-calendar"].enabled;
        },
    },
    methods: {
        ...mapActions({
            reloadIntranet: INTRANET_MODULE_NAME + RELOAD_CURRENT_INTRANET
        }),
        async disableIntegrationHint() {
            this.disablingIntegrationHint = true;
            try {
                const api = await new ApiClientBuilder().build();
                await api.put(`${cloudRunUrl.intranet}/api/intranet/${this.intranet.uid}/flags/disable-integration-hint`);
                this.reloadIntranet();
            } catch {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("error_disabling_hint")
                });
                this.disablingIntegrationHint = false;
            } finally {
            }
        },
    }
};
</script>

<style lang="scss" scoped>
    .no-integrations-hint {
        display: flex;
        flex-direction: column;
        padding: 3rem 1rem;
        background-color: $primary;
        color: $primary-text;
        position: relative;
        border-radius: 0.5rem;
        margin-bottom: 2rem;
        transition: filter .3s ease, opacity .3s ease;

        &.hiding {
            filter: grayscale(1);
            opacity: 0.2;
        }

        .hide-integrations-trigger {
            position: absolute;
            top: 1rem;
            right: 1.5rem;

            .button {
                padding: 0;
            }
        }

        .no-integrations-hint-content {
            align-self: center;

            .vendor-icons {
                display: flex;
                justify-content: center;

                .vendor-icon {
                    width: 32px;
                    height: 32px;
                    margin: 8px;
                }
            }

            h3 {
                margin-bottom: 0.5rem;
            }
        }
    }

    .grid {
        .col.half {
            .card {
                height: 100%;
            }
        }
    }

    .triplet-grid {
        display: flex;
        margin: 0 -2rem 3rem;

        > div, > article {
            width: calc(30% - 2rem);
            flex: 1 0 auto;
            margin: 2rem;
        }
    }
</style>
