<template>
    <div
        v-if="isCompanyHappinessEnabled"
        class="card depth-1 padding-medium mbottom-small"
    >
        <HappinessSurvey type="team" />
        <div class="happiness-widget-space"></div>
        <HappinessSurvey type="company" />
    </div>
</template>

<script>
import Vue from "vue";

import HappinessSurvey from "@web/components/happiness/HappinessSurvey";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { IS_FEATURE_ENABLED } from "@web/store/intranet/getters";
import { IntranetFeatureFlag } from "@backend/common/feature-flags/types";

export default Vue.extend({
    name: "HappinessWidget",
    components: {
        HappinessSurvey,
    },
    computed: {
        ...mapGetters({
            isFeatureEnabled: INTRANET_MODULE_NAME + IS_FEATURE_ENABLED,
        }),
        isCompanyHappinessEnabled() {
            return this.isFeatureEnabled(IntranetFeatureFlag.companyHappiness);
        },
    }
});
</script>

<style lang="scss" scoped>
.happiness-widget-space {
    height: 1rem;
}
</style>
