<template>
    <div
        v-show="entity.views > 0"
        v-tooltip.top="$t('viewers')"
        class="control viewers"
        @click="openViewersModal()"
    >
        <Icon name="eye"/>
        {{ entity.views }}
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import { CURRENT_USER } from "@/store/auth/getters";
import { SubscriptionOnIntersection } from "@/lib/subscription-on-intersection";
import { viewService } from "@/services";

export default {
    name: "ViewersButton",
    props: {
        entity: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            subscriptionOnIntersection: undefined,
            unsubscribeViewsListener: undefined,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: AUTH_MODULE_NAME + CURRENT_USER
        })
    },
    mounted() {
        this.subscriptionOnIntersection = new SubscriptionOnIntersection({
            element: this.$el,
            subscribe: this.subscribeViewersListener,
            unsubscribe: this.unsubscribeViewersListener
        });
        this.subscriptionOnIntersection.init();
    },
    destroyed() {
        this.subscriptionOnIntersection.destroy();
    },
    methods: {
        openViewersModal() {
            this.$modal.show("viewers-modal", {
                entity: this.entity,
            });
        },
        subscribeViewersListener() {
            this.unsubscribeViewsListener = viewService.onViewsUpdates({
                docPath: this.entity.docPath,
                onDocUpdate: (viewTrackable) => {
                    console.log(viewTrackable);
                    this.entity.views = viewTrackable.views;
                },
            });
        },
        unsubscribeViewersListener() {
            if (!this.unsubscribeViewsListener) return;
            this.unsubscribeViewsListener();
        }
    }
};
</script>

<style scoped>

</style>
