<template>
    <modal
        name="user-stories"
        :dark="true"
        :unavoidable="true"
        fullscreen
        @before-open="beforeOpen"
    >
        <div
            class="click-container"
            @click="handleClick"
        >
            <LoadingWrapper
                ref="wrapper"
                class="detail-spinner"
                :is-loading="loading"
            >
                <user-story-slides
                    v-if="!loading"
                    class="user-story-slides"
                    :initial-user-uid="creatorUid"
                    @close="onClose"
                    @onStoryDeleteBtn="onStoryDeleteBtn"
                />
            </LoadingWrapper>
        </div>
        <template v-slot:controls>
            <div
                class="control close"
                @click="onClose()"
            >
                <Icon name="x"/>
            </div>
        </template>
        <delete-confirmation @delete="deleteStory"/>
    </modal>
</template>

<script>
import Vue from "vue";
import Modal from "@web/components/modals/Modal.vue";
import UserStorySlides from "@web/views/story/UserStorySlides.vue";
import {
    mapActions,
    mapGetters
} from "vuex";
import { STORY_MODULE_NAME } from "@web/store/story/story";
import {
    DELETE_STORY_FROM_USER,
    FETCH_USER_STORIES,
    RESET_USER_STORIES
} from "@web/store/story/actions";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { CURRENT_USER } from "@web/store/auth/getters";
import DeleteConfirmation from "@web/views/story/DeleteConfirmation.vue";
import { GET_USER_STORIES } from "@web/store/story/getters";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

export default Vue.extend({
    name: "UserStories",
    components: {
        UserStorySlides,
        Modal,
        DeleteConfirmation
    },
    data() {
        return {
            loading: false,
            creatorUid: "",
            openedTimestamp: 0,
        };
    },
    computed: {
        ...mapGetters({
            userStories: STORY_MODULE_NAME + GET_USER_STORIES,
            currentUser: AUTH_MODULE_NAME + CURRENT_USER
        }),
    },
    methods: {
        ...mapActions({
            fetchUserStories: STORY_MODULE_NAME + FETCH_USER_STORIES,
            deleteStoryFromUser: STORY_MODULE_NAME + DELETE_STORY_FROM_USER,
            resetUserStories: STORY_MODULE_NAME + RESET_USER_STORIES
        }),
        async beforeOpen(event) {
            this.creatorUid = event.params.creatorUid;
            analytics.log(getGlobalConfiguration().analytics_event_name_stories_opened);
            this.openedTimestamp = Date.now();
        },
        onClose(event) {
            const closedCause = event && event.closedAfterLast ? getGlobalConfiguration().analytics_param_stories_closed_after_last : getGlobalConfiguration().analytics_param_stories_closed_button_click;
            const viewDurationSeconds = Math.round((Date.now() - this.openedTimestamp) / 1000);
            analytics.log(getGlobalConfiguration().analytics_event_name_stories_closed, { closedCause, viewDurationSeconds });
            this.close();
        },
        onStoryDeleteBtn(data) {
            this.$modal.show("delete-confirmation", { story: data.story });
        },
        close() {
            this.resetUserStories();
            this.$modal.hide("user-stories");
        },
        handleClick(e) {
            if (this.$refs.wrapper && e.target === this.$refs.wrapper.$el) this.close();
        },
        async deleteStory(data) {
            try {
                if (this.currentUser.uid === data.story.creatorUid) {
                    this.loading = true;
                    await this.deleteStoryFromUser({ userUid: this.currentUser.uid, story: data.story });
                    this.$emit("delete", { key: data.story.creatorUid + data.story.uid });
                    this.loading = false;
                    this.$notify({
                        group: "app",
                        type: "success",
                        text: this.$t("story_deleted"),
                    });
                    analytics.log(getGlobalConfiguration().analytics_event_name_story_deleted);
                    this.close();
                }
            } catch (e) {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("story_delete_error"),
                });
            } finally {
                this.loading = false;
            }
        },
    }
});
</script>

<style lang="scss" scoped>

    .detail-spinner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .user-story-slides {
        margin: auto;
        max-width: 50vh;
        position: relative;
        margin-top: 50vh;
        transform: translateY(-50%);

        ::v-deep {
            .vueperslides__progress {
                position: absolute;
                top: 30px;
                left: 2rem;
                right: 2rem;
                z-index: 1;
                height: 4px;
                background: rgba($black, $opacity-twenty);
                color: $white;
                border-radius: 5px;

                > div {
                    border-radius: 5px;
                }
            }
            .vueperslides__fractions {
                position: absolute;
                top: auto;
                left: auto;
                bottom: 2rem;
                right: 2rem;
                z-index: 2;
                padding: 0.25rem 0.5rem;
                border: none;
                border-radius: var(--border-radius);
                background: rgba($black, $opacity-twenty);
                color: $white;
            }
        }
    }
</style>
