<template>
    <div class="progress-bar">
        <div
            v-tooltip.bottom="toolTipText"
            class="progress"
            :style="progressStyle"
        ></div>
    </div>
</template>

<script>
export default {
    name: "ProgressBar",
    props: {
        max: { type: Number, required: true },
        current: { type: Number, required: true },
    },
    computed: {
        progressStyle() {
            return {
                width: `${this.current / this.max * 100}%`
            };
        },
        toolTipText() {
            if (this.current / this.max * 100 < 5) {
                return "5%";
            }
            return `${Math.floor(this.current / this.max * 100)}%`;
        }
    },
};
</script>

<style lang="scss" scoped>
.progress-bar {
    height: 1.5rem;
    width: 100%;
    border-radius: 1rem;
    background-color: var(--lowest-contrast);
    .progress {
        min-width: 5%;
        height: 100%;
        border-radius: 1rem 0 0 1rem;
        background-color: var(--primary);
        transition: width 1s ease 0s;
    }
}
</style>

<docs>
```vue
<template>
    <div>
        <progress-bar
            :max="10"
            :current="progress"
        />
        <button @click="incProgress">+ increase progress</button>
        <button @click="decProgress">- decrease progress</button>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                progress: 1,
            };
        },
        methods: {
            incProgress() {
                if (this.progress < 10) {
                    this.progress++;
                }
            },
            decProgress() {
                if (this.progress > 1) {
                    this.progress--;
                }
            },
        },
    };
</script>
```
</docs>
