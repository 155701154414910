<template>
    <transition name="fade">
        <PuSkeleton
            class="skeleton"
            :loading="loading"
        >
            <div
                v-if="!disabledOrCompleted"
                class="profile-progress-widget card depth-1 padding-medium mbottom-small"
            >
                <div class="header">
                    <h3>{{ $t("profile_progress_header_text") }}</h3>
                    <a
                        class="skip clickable hover-underline"
                        @click="skip"
                    >
                        <p>{{ $t("skip") }}</p>
                    </a>
                </div>
                <div class="body mbottom-small">
                    <div class="top-text text text-high-contrast">
                        {{ nextLevel.topTextKey ? $t(nextLevel.topTextKey) : "" }}
                    </div>
                    <div
                        class="progress-image-wrapper mtop-small mbottom-small"
                        v-html="require(`@web/assets/profile/plant_${levelCount + 1}.svg`)"
                    ></div>
                    <progress-bar
                        class="mbottom-small"
                        :max="maxLevel"
                        :current="levelCount"
                    />
                    <div class="text">
                        {{ $t(nextLevel.bottomTextKey) }}
                    </div>
                </div>
                <div class="footer">
                    <app-button
                        :href="`/intranet/${intranetUid}/people/${currentUser.uid}/${nextLevel.id}`"
                        type="outline primary"
                        @click.native="logNavigationAnalytics"
                    >
                        {{ $t(nextLevel.buttonTextKey) }}
                    </app-button>
                </div>
            </div>
        </PuSkeleton>
    </transition>
</template>

<script>
import ProgressBar from "@/components/profile/ProgressBar";
import { mapGetters } from "vuex";
import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import { CURRENT_USER } from "@/store/auth/getters";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { INTRANET_UID } from "@/store/intranet/getters";
import {
    profileAuxiliaryService,
    profileService,
} from "@/services";
import {
    getProfileProgress,
    PROFILE_LEVELS,
    ProfileSection,
} from "@/components/profile/profile-progress-helper";
import {
    getPastDaysSinceThen,
    toDayJs,
} from "@/lib/time-utils";
import { analytics } from "@/analytics";
import { getGlobalConfiguration } from "@/global-config";

export default {
    name: "ProfileProgressWidget",
    components: { ProgressBar },
    data() {
        return {
            disabledOrCompleted: false,
            loading: true,
            levelCount: 1,
            nextLevel: PROFILE_LEVELS[0],
            maxLevel: Object.keys(ProfileSection).length - 1,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
        }),
    },
    async mounted() {
        try {
            const fullProfile = await profileService.getFullUserProfile(this.intranetUid, this.currentUser.uid);
            const { levelCount, level } = getProfileProgress(fullProfile);
            this.levelCount = levelCount;
            this.nextLevel = level;
            this.disabledOrCompleted = level.id === ProfileSection.completed;
            if (!this.disabledOrCompleted && fullProfile.progressHintSkippedTimestamp) {
                const lastSkippedDate = toDayJs(fullProfile.progressHintSkippedTimestamp);
                const daysSinceLastSkipped = getPastDaysSinceThen(lastSkippedDate);
                this.disabledOrCompleted = daysSinceLastSkipped < 7;
            }
        } catch (e) {
            this.$notify({
                group: "app",
                type: "error",
                title: this.$t("profile_progress_loading_error"),
                text: e.message,
            });
            this.disabledOrCompleted = true;
        } finally {
            this.loading = false;
        }
    },
    methods: {
        logNavigationAnalytics() {
            analytics.log(getGlobalConfiguration().analytics_event_name_profile_progress_navigation_used);
        },
        skip() {
            this.disabledOrCompleted = true;
            try {
                profileAuxiliaryService.updateProgressSkipTimestamp(this.intranetUid, this.currentUser.uid);
                analytics.log(getGlobalConfiguration().analytics_event_name_profile_progress_skipped);
            } catch (e) {
                this.$notify({
                    group: "app",
                    type: "error",
                    title: this.$t("profile_progress_skip_error_title"),
                    text: e.message,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.skeleton {
    height: 25rem;
    width: 100%;
    ::v-deep .pu-skeleton {
        margin-bottom: 2rem;
    }
}

.profile-progress-widget {
    border-radius: var(--border-radius);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.skip {
    color: var(--secondary);
    font-weight: 600;
}

.body {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .text {
        width: fit-content;
        margin: auto;
        text-align: center;
    }

    .top-text {
        max-width: 80%;
    }
}

.progress-image-wrapper {
    display: flex;
    justify-content: center;
    ::v-deep {
        svg {
            height: 7rem;
            margin: auto;
        }
    }
}

.footer {
    display: flex;
    justify-content: center;
}

</style>
