import { UserProfileWithContentInFirestore } from "@backend/user-profile/types";
import { emptyContent } from "@web/lib/quill/quill-utils";
import { cloneDeepClean } from "@web/lib/cloneDeepClean";

const ignoreKeys = [
    "displayName",
    "team",
    "profileCoverImageStoragePath",
];

export enum ProfileSection {
    pictures = "pictures",
    header = "header",
    contact = "contact",
    aboutMe = "aboutMe",
    info = "info",
    language = "language",
    skill = "skill",
    completed = "completed",
}

export const PROFILE_LEVELS: ProfileLevel[] = [
    {
        id: ProfileSection.pictures,
        fields: ["profileImageStoragePath"],
        topTextKey: "profile_progress_top_text_pictures",
        bottomTextKey: "profile_progress_bottom_text_pictures",
        buttonTextKey: "profile_progress_button_text_pictures",
    },
    {
        id: ProfileSection.header,
        fields: ["jobTitle", "location", "team"],
        topTextKey: "profile_progress_top_text_work_details",
        bottomTextKey: "profile_progress_bottom_text_work_details",
        buttonTextKey: "profile_progress_button_text_work_details",
    },
    {
        id: ProfileSection.contact,
        fields: ["phone"],
        topTextKey: "profile_progress_top_text_contact",
        bottomTextKey: "profile_progress_bottom_text_contact",
        buttonTextKey: "profile_progress_button_text_contact",
    },
    {
        id: ProfileSection.aboutMe,
        fields: ["aboutMe"],
        topTextKey: "profile_progress_top_text_about_me",
        bottomTextKey: "profile_progress_bottom_text_about_me",
        buttonTextKey: "profile_progress_button_text_about_me",
    },
    {
        id: ProfileSection.info,
        fields: ["birthday", "hiringDate"],
        topTextKey: "profile_progress_top_text_celebration_dates",
        bottomTextKey: "profile_progress_bottom_text_celebration_dates",
        buttonTextKey: "profile_progress_button_text_celebration_dates",
    },
    {
        id: ProfileSection.language,
        fields: ["languages"],
        topTextKey: "profile_progress_top_text_languages",
        bottomTextKey: "profile_progress_bottom_text_languages",
        buttonTextKey: "profile_progress_button_text_languages",
    },
    {
        id: ProfileSection.skill,
        fields: ["skills"],
        topTextKey: "profile_progress_top_text_skills",
        bottomTextKey: "profile_progress_bottom_text_skills",
        buttonTextKey: "profile_progress_button_text_skills",
    },
];

const completedLevel: ProfileLevel = {
    id: ProfileSection.completed,
    fields: [],
    topTextKey: "",
    bottomTextKey: "profile_progress_bottom_text_completed",
    buttonTextKey: "profile_progress_button_text_completed",
};

export interface ProfileLevel {
    id: ProfileSection;
    fields: string[];
    topTextKey: string;
    bottomTextKey: string;
    buttonTextKey: string;
}

interface ProfileProgress {
    levelCount: number;
    level: ProfileLevel;
}

// consider using https://github.com/kimamula/ts-transformer-keys
const profileKeys = [
    "displayName",
    "birthday",
    "hiringDate",
    "jobTitle",
    "location",
    "team",
    "languages",
    "skills",
    "phone",
    "profileImageStoragePath",
    "profileCoverImageStoragePath",
    "aboutMe",
];

export function getProfileProgress(userFullProfile: UserProfileWithContentInFirestore): ProfileProgress {
    const missingFieldKeys = profileKeys
        .filter((key) => {
            let rawField = userFullProfile[key as keyof UserProfileWithContentInFirestore];
            // profileImageStoragePath is the only field with a valid fallback value
            if (key === "profileImageStoragePath") {
                rawField = rawField ?? userFullProfile["photoURL" as keyof UserProfileWithContentInFirestore];
            }
            if (!rawField) return !ignoreKeys.includes(key);
            const field = cloneDeepClean(rawField);
            return isFieldEmpty(field) && !ignoreKeys.includes(key);
        });
    let levelCount = 0;
    let nextLevel: ProfileLevel = completedLevel;
    Object.values(PROFILE_LEVELS).reverse()
        .forEach((level: ProfileLevel) => {
            const hasMissing = level.fields.some((key) => missingFieldKeys.includes(key));
            if (hasMissing) {
                nextLevel = level;
            } else {
                levelCount++;
            }
        });
    return {
        levelCount,
        level: nextLevel,
    };
}

function isFieldEmpty(field: any) {
    const isEmptyArray = Array.isArray(field) && field.length === 0;
    const isEmptyArrayString = field === "[]";
    const isEmptyString = field === "";
    const isEmptyDelta = JSON.stringify(field) === JSON.stringify(emptyContent());
    return isEmptyArray || isEmptyArrayString || isEmptyString || isEmptyDelta;
}
