<template>
    <transition
        name="moveInFromTop"
        appear
        @after-leave="$emit('interacted')"
    >
        <div
            v-if="active"
            class="card depth-2 padding-medium"
            :class="{ [variant]: true }"
            @mouseover="showInteraction = socialNotificationType !== undefined"
            @mouseleave="showInteraction = interacted"
        >
            <div class="bubble bubble-top-right" ></div>
            <div class="bubble bubble-middle-right" ></div>
            <div class="bubble bubble-bottom-left" ></div>
            <div class="bubble bubble-avatar-background" ></div>
            <div class="emojis foreground">
                <div class="emoji accent-emoji">
                    <div>{{ accentEmoji }}</div>
                </div>
                <div class="emoji main-emoji">
                    {{ emoji }}
                </div>
            </div>
            <div class="foreground content mtop-medium">
                <div
                    class="text"
                    :class="{ blur: showInteraction }"
                >
                    <slot></slot>
                </div>
                <div
                    v-if="socialNotificationType"
                    class="interact-container center-container"
                    :class="{ show: showInteraction }"
                >
                    <app-button
                        type="light"
                        @click="dispatchSocialNotification"
                    >
                        <span class="mright-xsmall">{{ buttonEmoji }}</span> {{ actualButtonText }}
                    </app-button>
                </div>
            </div>
            <router-link :to="{ name: 'user-profile', params: { userUid: happening.user.uid }}">
                <div class="avatar foreground">
                    <img :src="happening.user.photoURL" />
                </div>
            </router-link>
        </div>
    </transition>
</template>

<script>
import { activityDispatcherService } from "@/services";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { INTRANET_UID } from "@/store/intranet/getters";
import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import { CURRENT_USER } from "@/store/auth/getters";
import { SOCIAL_NOTIFICATION_TYPE_EMOJI_MAP } from "@/components/social-notifications/config";
import { wait } from "@/lib/wait";

export default {
    name: "SocialHappeningCard",
    props: {
        /**
         * @type {SocialHappening}
         */
        happening: {
            type: Object,
            required: true,
        },
        /**
         * @values "primary", "secondary"
         */
        variant: {
            type: String,
            default: "primary",
        },
        emoji: {
            type: String,
            default: function() {
                return SOCIAL_NOTIFICATION_TYPE_EMOJI_MAP[this.happening.type];
            }
        },
        accentEmoji: {
            type: String,
            default: function() {
                return this.emoji;
            }
        },
        buttonText: String,
        /**
         * The social notification type which can be dispatched by this card. If this is not set, no button is displayed
         *
         * @type {SocialNotificationType}
         */
        socialNotificationType: String,
    },
    data() {
        return {
            showInteraction: false,
            interacted: false,
            active: true,
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
        actualButtonText() {
            return this.interacted ? this.$t("done") : this.buttonText;
        },
        buttonEmoji() {
            return this.interacted ? "✅" : this.emoji;
        },
    },
    methods: {
        async dispatchSocialNotification() {
            this.interacted = true;
            activityDispatcherService.dispatchSocialNotification({
                intranetUid: this.intranetUid,
                recipientUid: this.happening.user.uid,
                socialNotificationType: this.socialNotificationType,
            });
            await wait(1000);
            this.active = false;
        },
    }
};
</script>

<style lang="scss" scoped>
.card {
    border: none;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    background: var(--primary);
    color: var(--primary-text);

    &.secondary {
        background: var(--secondary);
        color: var(--secondary-text);
    }

    &:hover {
        .bubble, .emoji {
            animation-play-state: running;
        }
    }

    .foreground {
        z-index: 1;
    }

    .content {
        position: relative;

        .text {
            transition: filter .2s linear;
            &.blur {
                filter: blur(3px);
            }

            ::v-deep {
                h3 {
                    margin-bottom: .3rem;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }

        .interact-container {
            transition: all .2s ease-out;
            position: absolute;
            top: 30%;
            opacity: 0;

            &.show {
                top: 0;
                opacity: 1;
            }
        }
    }

    .bubble {
        position: absolute;
        background: white;
        opacity: .3;
        border-radius: 50%;
        animation-name: wiggle;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-fill-mode: both;
        animation-play-state: paused;

        &.bubble-top-right {
            width: 220px;
            height: 220px;
            top: -10px;
            right: -90px;
            animation-duration: 6s;
        }

        &.bubble-middle-right {
            width: 120px;
            height: 120px;
            top: calc(50% - 20px);
            right: -50px;
            animation-duration: 4s;
        }

        &.bubble-bottom-left {
            width: 100px;
            height: 100px;
            bottom: -30px;
            left: -30px;
            animation-duration: 2.8s;
        }

        &.bubble-avatar-background {
            width: 195px;
            height: 195px;
            top: -30px;
            left: -35px;
        }
    }

    .emoji, .bubble-avatar-background {
        animation-name: heartBeat;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-play-state: paused;
    }

    .bubble-avatar-background {
        animation-name: subtleHeartBeat;
        animation-duration: 10s;
    }

    .emojis {
        height: calc(160px - 1.5rem);
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        line-height: 1;

        .main-emoji {
            font-size: 3rem;
            margin-left: .5rem;
            animation-duration: 2s;
        }

        .accent-emoji {
            font-size: 1.6rem;
            animation-duration: 1s;

            div {
                transform: scaleX(-1);
            }
        }
    }

    .avatar {
        border-width: 4px;
        position: absolute;
        top: -30px;
        left: -30px;
        height: 190px;
        width: 190px;

        img {
            transition: transform .2s;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}
</style>
