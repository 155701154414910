<template>
    <Modal
        name="delete-confirmation"
        :unavoidable="true"
        title="Delete confirmation"
        @before-open="beforeOpen"
    >
        <template #body>
            <div class="grid">
                <div class="col two-thirds">
                    {{ $t("story_delete_confirm") + " " + $t("cannot_be_undone") }}
                </div>
            </div>
        </template>
        <template #footer>
            <div class="grid">
                <div class="left">
                    <app-button
                        class="cancel"
                        type="link"
                        @click="onCancelButton"
                    >
                        {{ $t("button_cancel") }}
                    </app-button>
                </div>
                <div class="right">
                    <app-button
                        type="warning"
                        @click="onDeleteButton"
                    >
                        {{ $t("button_delete") }}
                    </app-button>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import Vue from "vue";
import Modal from "@web/components/modals/Modal.vue";

export default Vue.extend({
    name: "DeleteConfirmation",
    components: {
        Modal
    },
    data() {
        return {
            story: {}
        };
    },
    methods: {
        async beforeOpen(event) {
            this.story = event.params.story;
        },
        onDeleteButton() {
            this.$emit("delete", { story: this.story });
            this.$modal.hide("delete-confirmation");
        },
        onCancelButton() {
            this.$modal.hide("delete-confirmation");
        }
    }
});
</script>

<style lang="scss" scoped>
.cancel{
    padding: 0.5rem 0;
}
</style>
