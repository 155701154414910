<template>
    <transition name="fade">
        <div
            v-if="notifications.length"
            class="card depth-1"
        >
            <h3 class="ptop-medium pright-medium pleft-medium">
                {{ $t("social_inbox_title") }}
            </h3>
            <social-notification-list
                class="pbottom-medium pright-medium pleft-medium"
                :notifications="notifications"
                :mark-as-read-callback="markAsRead"
                :loading="loading"
                @load-more="loadMore()"
                @start-interaction="setSelectedForInteraction"
            />
        </div>
    </transition>
</template>

<script>
import SocialNotificationList from "@/components/social-notifications/SocialNotificationList";
import {
    mapActions,
    mapGetters,
    mapMutations,
} from "vuex";
import {
    SOCIAL_NOTIFICATION_MODULE_NAME
} from "@/store/social-notifications/social-notifications";
import {
    GET_SOCIAL_NOTIFICATIONS,
    IS_LOADING
} from "@/store/social-notifications/getters";
import {
    LOAD_MORE,
    MARK_AS_READ
} from "@/store/social-notifications/actions";
import { SET_SELECTED_FOR_INTERACTION } from "@/store/social-notifications/mutations";

export default {
    name: "SocialInbox",
    components: { SocialNotificationList },
    computed: {
        ...mapGetters({
            loading: SOCIAL_NOTIFICATION_MODULE_NAME + IS_LOADING,
            notifications: SOCIAL_NOTIFICATION_MODULE_NAME + GET_SOCIAL_NOTIFICATIONS,
        }),
    },
    methods: {
        ...mapMutations({
            setSelectedForInteraction: SOCIAL_NOTIFICATION_MODULE_NAME + SET_SELECTED_FOR_INTERACTION,
        }),
        ...mapActions({
            markAsRead: SOCIAL_NOTIFICATION_MODULE_NAME + MARK_AS_READ,
            loadMore: SOCIAL_NOTIFICATION_MODULE_NAME + LOAD_MORE,
        }),
    },
};
</script>

<style lang="scss" scoped>
.social-notification-list {
    padding-right: .5rem;
    margin-right: 1rem;
}
</style>
