<template>
    <social-happening-card
        :happening="happening"
        :variant="variant"
        :button-text="$t('social_happenings_interaction_say_hey')"
        :social-notification-type="socialNotificationType"
        @interacted="$emit('interacted')"
    >
        <h3>{{ $t("social_happenings_new_employee_title") }}</h3>
        <p v-html="text" ></p>
    </social-happening-card>
</template>

<script>
import SocialHappeningCard from "@/components/social-happenings/SocialHappeningCard";
import escape from "lodash/escape";
import dayjs from "dayjs";
import { SocialNotificationType } from "@backend/common/notification/activity-types";

export default {
    name: "NewEmployeeCard",
    components: { SocialHappeningCard },
    props: {
        /**
         * @type {SocialHappening}
         */
        happening: {
            type: Object,
            required: true,
        },
        /**
         * @values "primary", "secondary"
         */
        variant: {
            type: String,
            default: "primary",
        },
    },
    data() {
        return {
            socialNotificationType: SocialNotificationType.newEmployee,
        };
    },
    computed: {
        displayName() {
            return `<strong>${escape(this.happening.user.displayName)}</strong>`;
        },
        date() {
            return `<strong>${
                this.isToday ? this.$t("today") : escape(this.happening.date.format("DD.MM.YYYY"))
            }</strong>`;
        },
        text() {
            const key = this.isToday
                ? "social_happenings_new_employee_text_today"
                : "social_happenings_new_employee_text";
            return this.$t(key, [this.displayName, this.date]);
        },
        isToday() {
            return this.happening.date.isSame(dayjs(), "day");
        },
    }
};
</script>

<style lang="scss" scoped>
::v-deep {
    .emoji {
        animation-name: wave !important;
        animation-timing-function: linear !important;
        transform-origin: 70% 70%;

        &.main-emoji {
            animation-duration: 1s !important;
        }

        &.accent-emoji {
            animation-duration: .5s !important;
            transform-origin: 30% 70%;
        }
    }
}
</style>
