<template>
    <article class="google-cal-integration">
        <div
            v-if="googleCalendarFeatureConfig.enabled"
            class="card depth-1 padding-medium mbottom-small"
        >
            <h3>
                <span>{{ $t("your_day") }}</span>
                <a
                    href="https://calendar.google.com"
                    target="_blank"
                >
                    <div
                        class="vendor-icon"
                        v-html="require(`@web/assets/vendors/google-icon.svg`)"
                    ></div>
                </a>
            </h3>
            <LoadingWrapper :is-loading="loading || isFetchingUserConnection">
                <template v-if="isFeatureAvailable">
                    <ActivityGroup
                        v-for="event in events"
                        :key="event.id"
                        :small="true"
                    >
                        <CalendarEventRow
                            :title="event.summary"
                            :subtitle="event.location"
                            :time="getTime(event)"
                            :url="event.htmlLink"
                        />
                    </ActivityGroup>
                    <p
                        v-if="!loadingError && events.length === 0"
                        class="no-events-in-calendar"
                    >
                        {{ $t("no_events_today") }}
                    </p>
                    <p
                        v-if="loadingError"
                        class="error centered"
                    >
                        <strong>{{ $t("unknown_error") }}</strong><br/>
                        {{ $t("unknown_error_text") }}
                    </p>
                </template>
                <div
                    v-if="offerReconnect"
                    class="centered"
                >
                    <p>{{ $t("connect_with_google_connection_needed") }}</p>
                    <p
                        v-if="connectingError"
                        class="error"
                    >
                        {{ $t("connect_with_google_calendar_err_declined") }}
                    </p>
                    <app-button
                        class="action-button"
                        :busy="connectingToGoogle"
                        @click="startGoogleCalendarUserConnectionFlow"
                    >
                        {{ $t("connect_with_google_connection_start") }}
                    </app-button>
                </div>
            </LoadingWrapper>
        </div>
    </article>
</template>

<script>
import Vue from "vue";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    mapActions,
    mapState,
} from "vuex";
import { GOOGLE_CALENDAR_MODULE_NAME } from "@web/store/google/google-calendar";
import {
    googleCalendarConnectService,
    googleContentService,
} from "@web/services";
import dayjs from "dayjs";
import CalendarEventRow from "@web/views/google/CalendarEventRow";
import ActivityGroup from "@web/views/atlassian/ActivityGroup";
import { FETCH_USER_CONNECTION_STATUS } from "@web/store/google/actions";

export default Vue.extend({
    name: "GoogleCalendarActivity",
    components: {
        CalendarEventRow,
        ActivityGroup,
    },
    data() {
        return {
            result: {
                items: [],
            },
            loading: false,
            requireReconnect: false,
            connectingToGoogle: false,
            connectingError: false,
            loadingError: false,
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
        ...mapState(GOOGLE_CALENDAR_MODULE_NAME, {
            isUserConnected: "isUserConnected",
            isFetchingUserConnection: "isFetching",
        }),
        googleCalendarFeatureConfig() {
            return this.intranet.flags["google-calendar"];
        },
        offerReconnect() {
            if (!this.googleCalendarFeatureConfig.enabled) return false;
            if (this.requireReconnect) return true;
            return !this.isUserConnected;
        },
        isFeatureAvailable() {
            if (!this.googleCalendarFeatureConfig.enabled) return false;
            if (this.requireReconnect) return false;
            return this.isUserConnected;
        },
        events() {
            return this.result.items
                // event can have no attendees, this should be an event only the user attends to
                .filter(({ attendees }) => {
                    if (!attendees) {
                        return true;
                    }
                    // if there are attendees, the user himself could NOT attend to it
                    const selfAttendee = attendees.find(a => a.self === true);
                    if (!selfAttendee) {
                        return false;
                    }
                    return selfAttendee.responseStatus === "accepted" || selfAttendee.responseStatus === "tentative";
                })
                .filter(e => {
                    // events that don't end on a dateTime are full-day events which are shown
                    if (e.end.dateTime == null) {
                        return true;
                    }
                    // we don't show events that have already ended today
                    return dayjs(e.end.dateTime).isAfter(dayjs());
                }).slice(0, 5);
        },
    },
    watch: {
        isFeatureAvailable() {
            this.fetchCalendarContent();
        },
    },
    methods: {
        ...mapActions({
            fetchUserConnectionStatus: GOOGLE_CALENDAR_MODULE_NAME + FETCH_USER_CONNECTION_STATUS,
        }),
        async startGoogleCalendarUserConnectionFlow() {
            try {
                this.connectingToGoogle = true;
                await googleCalendarConnectService.startUserConnectionFlow(this.intranet.uid);
                this.requireReconnect = false;
                await this.fetchCalendarContent();
            } catch (e) {
                this.connectingError = true;
            } finally {
                this.connectingToGoogle = false;
            }
        },
        async fetchCalendarContent() {
            if (!this.isFeatureAvailable) {
                return;
            }
            this.loading = true;
            try {
                this.result = await googleContentService.fetchMyEvents(this.intranet.uid);
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    this.requireReconnect = true;
                    return;
                }
                this.loadingError = true;
            } finally {
                this.loading = false;
            }
        },
        getTime(event) {
            // missing i18n is intentional for 'today'
            return this.isFullDayEvent(event) ? "TODAY" : dayjs(event.start.dateTime).format("HH:mm");
        },
        isFullDayEvent(event) {
            const d = new Date();
            d.setHours(0, 0, 0, 0);
            const now = dayjs(d);
            // an event that ends today but started before today is considered as full-day
            return event.start.date != null || dayjs(event.start.dateTime).isBefore(now);
        },
    },
    mounted() {
        if (!this.isFeatureAvailable) {
            this.fetchUserConnectionStatus();
            return;
        }
        this.fetchCalendarContent();
    },
});
</script>

<style lang="scss" scoped>

.vendor-icon {
    width: 24px;
}

.no-events-in-calendar {
    padding: 2rem;
    text-align: center;
    font-style: italic;
    color: #999;
}

h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
