<template>
    <LoadingWrapper :is-loading="isUserConnecting || isFetching">
        <div v-if="isAtlassianFeatureEnabledInIntranet">
            <div
                v-if="!isUserConnected"
                class="atlassian-content"
            >
                <div
                    v-if="showUserConnectActions"
                    class="atlassian-empty"
                >
                    <div
                        class="vendor-icon"
                        style="width: 3rem; margin: 0 auto 0.5rem;"
                        v-html="require(`@web/assets/vendors/atlassian-icon.svg`)"
                    ></div>
                    <p>{{ $t("connect_with_atlassian_connection_needed") }}: <b>{{ atlassianFeatureConfig.config.instanceUrl }}</b></p>
                    <app-button
                        class="action-button"
                        @click="startUserConnectionFlow"
                    >
                        {{ $t("connect_with_atlassian_connection_start") }}
                    </app-button>
                </div>
            </div>
            <div
                v-else
                class="atlassian-content"
            >
                <div class="atlassian-content-inner">
                    <slot></slot>
                </div>
            </div>
        </div>
    </LoadingWrapper>
</template>

<script>
import Vue from "vue";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    mapActions,
    mapState,
} from "vuex";
import { analytics } from "@web/analytics";
import { atlassianConnectService } from "@web/services";
import { getGlobalConfiguration } from "@web/global-config";
import { ATLASSIAN_MODULE_NAME } from "@web/store/atlassian/atlassian";
import { FETCH_USER_CONNECTION_STATUS } from "@web/store/atlassian/actions";

export default Vue.extend({
    name: "AtlassianAccessWrapper",
    components: {},
    props: {
        product: {
            type: String,
        },
        showUserConnectActions: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isUserConnecting: false,
            results: [],
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
        ...mapState(ATLASSIAN_MODULE_NAME, ["isFetching", "isUserConnected"]),
        atlassianFeatureConfig() {
            return this.intranet.flags["atlassian-3lo"];
        },
        isAtlassianFeatureEnabledInIntranet() {
            return this.atlassianFeatureConfig.enabled && this.atlassianFeatureConfig.config && this.atlassianFeatureConfig.config.products.includes(this.product);
        },
    },
    mounted() {
        this.fetchStatus();
    },
    methods: {
        ...mapActions({
            fetchStatus: ATLASSIAN_MODULE_NAME + FETCH_USER_CONNECTION_STATUS,
        }),
        async removeUserConnection() {
            await atlassianConnectService.removeUserConnection(this.intranet.uid);
            this.fetchStatus();
        },
        async startUserConnectionFlow() {
            this.isUserConnecting = true;
            const popup = await atlassianConnectService.startUserConnectionFlow(this.intranet.uid);
            const channel = new BroadcastChannel("connect-atlassian-user");
            channel.onmessage = ({ data }) => {
                const msg = data.data;
                this.fetchStatus();
                this.isUserConnecting = false;
                analytics.log(getGlobalConfiguration().analytics_event_name_atl_cloud_connected_by_user);
                popup.close();

                this.$notify({
                    group: "app",
                    type: msg.includes("success") ? "success" : "error",
                    text: this.$t(msg),
                    duration: 8000,
                });
                channel.close();
            };
        },
    },
});
</script>

<style lang="scss" scoped>
#remove-user-access {
    font-size: 12px;
    font-style: italic;
    width: 100%;
    text-align: right;
    color: var(--medium-contrast, #{$mid-grey});
    cursor: pointer;
}

.atlassian-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.atlassian-content-inner {
    #start-connection-flow {
        margin-top: 10px;
    }
}

.atlassian-empty {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;

    #start-connection-flow {
        margin-top: 10px;
    }
}
</style>
