<template>
    <transition name="fade">
        <div
            v-if="happenings.length"
            v-resize-callback.initial="updateSwiperOptions"
            class="social-happenings-overview"
            @mouseenter="$refs.swiper && $refs.swiper.$swiper.autoplay.stop()"
            @mouseleave="$refs.swiper && $refs.swiper.$swiper.autoplay.start()"
        >
            <div class="social-happenings-header">
                <h3 class="mbottom-small">
                    {{ $t("social_happenings_title") }}
                </h3>
                <router-link
                    class="show-all-link link"
                    :to="{ name: 'social' }"
                    @click.native="socialOverViewAnalyticsLog"
                >
                    {{ $t("show_all") }}
                </router-link>
            </div>
            <swiper
                ref="swiper"
                class="swiper"
                :options="swiperOptions"
            >
                <swiper-slide
                    v-for="(item, index) in happenings"
                    :key="item.happening.type.toString() + item.happening.user.displayName + item.happening.date.toString()"
                    class="happening-slide"
                >
                    <component
                        :is="getSocialHappeningCard(item.happening.type)"
                        :happening="item.happening"
                        :variant="item.variant"
                        class="happening"
                        @interacted="happenings.splice(index, 1)"
                    />
                </swiper-slide>
                <div
                    slot="pagination"
                    class="swiper-pagination"
                ></div>
            </swiper>
        </div>
    </transition>
</template>

<script>
import { socialHappeningsService } from "@/services";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { INTRANET_UID } from "@/store/intranet/getters";
import { SocialNotificationType } from "@backend/common/notification/activity-types";
import NewEmployeeCard from "@/components/social-happenings/NewEmployeeCard";
import {
    Swiper,
    SwiperSlide
} from "vue-awesome-swiper";
import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import { CURRENT_USER } from "@/store/auth/getters";
import BirthdayCard from "@/components/social-happenings/BirthdayCard";
import AnniversaryCard from "@/components/social-happenings/AnniversaryCard";
import { analytics } from "@/analytics";
import { getGlobalConfiguration } from "@/global-config";

const HAPPENING_CARD_MAP = {
    [SocialNotificationType.newEmployee]: NewEmployeeCard,
    [SocialNotificationType.birthday]: BirthdayCard,
    [SocialNotificationType.anniversary]: AnniversaryCard,
};

export default {
    name: "SocialHappeningsOverview",
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            happenings: [],
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
        swiperOptions() {
            return {
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                slidesPerView: this.getSlidesPerView(),
                slidesPerGroup: this.getSlidesPerView(),
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                threshold: 10,
            };
        },
    },
    async mounted() {
        const happeningsList = await socialHappeningsService.list(this.intranetUid, this.currentUser.uid);
        this.happenings = happeningsList.map((happening, index) => ({
            happening,
            variant: index % 2 === 0 ? "primary" : "secondary",
        }));
    },
    methods: {
        socialOverViewAnalyticsLog() {
            analytics.log(getGlobalConfiguration().analytics_event_name_social_overview_opened_from_dashboard);
        },
        getSocialHappeningCard(type) {
            return HAPPENING_CARD_MAP[type];
        },
        updateSwiperOptions() {
            const slidesPerView = this.getSlidesPerView();
            if (slidesPerView !== this.$refs.swiper.$swiper.params.slidesPerView) {
                this.$refs.swiper.$swiper.params.slidesPerView = slidesPerView;
                this.$refs.swiper.$swiper.params.slidesPerGroup = slidesPerView;
                this.$refs.swiper.$swiper.updateSlides();
            }
        },
        getSlidesPerView() {
            if (this.$el.offsetWidth <= 460) {
                return 1;
            }
            return 2;
        },
    },
};
</script>

<style lang="scss" scoped>
.social-happenings-overview {
    .social-happenings-header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    .swiper {
        // negative margin is necessary to not cut of the card's shadow
        margin: -.5rem;

        ::v-deep {
            .swiper-pagination-bullet {
                background: var(--highest-contrast);
                width: 0.75rem;
                height: 0.75rem;
            }

            .swiper-pagination-bullet-active {
                background: var(--secondary);
            }
        }
    }

    .happening-slide {
        padding: .5rem .5rem 2.5rem;
        height: auto;

        .happening {
            height: 100%;
        }
    }
}

.show-all-link {
    color: var(--secondary);
}
</style>
