<template>
    <div>
        <Spinner
            v-if="loading"
            :key="'spinner'"
            size="32px"
        />
        <div v-else>
            <div
                v-if="hasPrevStory"
                v-fade-in
                class="button light only-icon story-button prev cursor-pointer"
                @click="previous"
            >
                <Icon name="arrow-left"/>
            </div>

            <vueper-slides
                ref="userSlides"
                class="no-shadow"
                slide-multiple
                :bullets-outside="configuration.bulletsOutside"
                :arrows="configuration.arrows"
                :visible-slides="configuration.visibleSlides"
                :infinite="configuration.infinite"
                :progress="configuration.progress"
                :autoplay="configuration.autoplay"
                :fractions="configuration.fractions"
                :fade="configuration.fade"
                :duration="configuration.duration"
                :transition-speed="configuration.transitionSpeed"
                :slide-ratio="configuration.slideRatio"
                :touchable="configuration.touchable"
                :bullets="configuration.bullets"
                :breakpoints="configuration.breakpoints"
                @slide="slideEvent($event)"
            >
                <vueper-slide
                    v-if="!userStories.length"
                >
                    <template v-slot:content>
                        <div class="no-longer-available-container center-container">
                            <div class="padding-medium border-radius-medium text-foreground">
                                {{ $t("story_no_longer_available") }}
                            </div>
                        </div>
                    </template>
                </vueper-slide>
                <vueper-slide
                    v-for="story in userStories"
                    v-else
                    :key="story.uid"
                >
                    <template v-slot:content>
                        <story-detail
                            :story="story"
                            class="cursor-pointer"
                            @onStoryDeleteBtn="onStoryDeleteBtn"
                        />
                    </template>
                </vueper-slide>
            </vueper-slides>

            <div
                v-if="hasNextStory"
                v-fade-in
                class="button light only-icon story-button next cursor-pointer"
                @click="next"
            >
                <Icon name="arrow-right"/>
            </div>
        </div>
    </div>
</template>

<script>
import StoryDetail from "@web/views/story/StoryDetail.vue";
import Spinner from "@web/components/Spinner.vue";
import Vue from "vue";
import { storyService } from "@web/services";
import {
    VueperSlide,
    VueperSlides
} from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    mapActions,
    mapGetters,
    mapState
} from "vuex";
import { STORY_MODULE_NAME } from "@web/store/story/story";
import { GET_USER_STORIES } from "@web/store/story/getters";
import {
    FETCH_USER_STORIES,
    RESET_USER_STORIES,
} from "@web/store/story/actions";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

const storyPerSlide = 1;

export default Vue.extend({
    name: "UserStorySlides",
    components: {
        StoryDetail,
        VueperSlides,
        VueperSlide,
        Spinner
    },
    props: {
        initialUserUid: { type: String, require: true },
    },
    data() {
        return {
            configuration: {
                visibleSlides: storyPerSlide,
                fade: false, // Fade false is necessary to trigger the IntersectionObserver in the view-trigger component
                duration: 4000,
                transitionSpeed: 300,
                bullets: false,
                progress: true,
                bulletsOutside: false,
                touchable: false,
                autoplay: false,
                arrows: false,
                infinite: false,
                fractions: true,
                slideRatio: 1.6,
                pauseOnHover: true,
                breakpoints: {}
            },
            currentSlideIndex: 0,
            limit: 100,
            loading: false,
            nextUserId: "",
            prevUserId: ""
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
        ...mapGetters({
            userStories: STORY_MODULE_NAME + GET_USER_STORIES,
        }),
        hasNextStory() {
            return this.hasNextStoryForUser || this.nextUserId;
        },
        hasNextStoryForUser() {
            return this.currentSlideIndex + storyPerSlide < this.userStories.length;
        },
        hasPrevStory() {
            return this.hasPrevStoryForUser || this.prevUserId;
        },
        hasPrevStoryForUser() {
            return this.currentSlideIndex > 0;
        }
    },
    created() {
        this.init();
    },
    mounted() {
        window.addEventListener("keydown", this._keyListener);
    },
    beforeDestroy() {
        window.removeEventListener("keydown", this._keyListener);
    },
    methods: {
        ...mapActions({
            fetchUserStories: STORY_MODULE_NAME + FETCH_USER_STORIES,
            resetUserStories: STORY_MODULE_NAME + RESET_USER_STORIES
        }),
        async next() {
            if (!this.hasNextStory) {
                this.$emit("close", { closedAfterLast: true });
                return;
            }
            analytics.log(getGlobalConfiguration().analytics_event_name_stories_next);
            if (!this.hasNextStoryForUser) {
                await this.tryToSwitchToNextUser();
                return;
            }
            this.$refs.userSlides.next();
        },
        async previous() {
            if (!this.hasPrevStory) {
                return;
            }
            analytics.log(getGlobalConfiguration().analytics_event_name_stories_prev);
            if (!this.hasPrevStoryForUser) {
                await this.tryToSwitchToPreviousUser();
                return;
            }
            this.$refs.userSlides.previous();
        },
        slideEvent(event) {
            this.currentSlideIndex = event.currentSlide.index;
        },
        async tryToSwitchToNextUser() {
            await this.openStoriesOfUser(this.nextUserId);
        },
        async tryToSwitchToPreviousUser() {
            await this.openStoriesOfUser(this.prevUserId);
        },
        async openStoriesOfUser(currentUserId) {
            if (!currentUserId) {
                return;
            }
            this.loading = true;
            await this.loadStories(currentUserId);
            this.currentSlideIndex = 0;
            this.loading = false;
            this.loadPrevAndNextUsers(currentUserId);
        },
        async loadStories(userUid) {
            this.resetUserStories();
            await this.fetchUserStories({ userUid, limit: this.limit });
        },
        loadPrevAndNextUsers(currentUserId) {
            this.nextUserId = "";
            this.prevUserId = "";
            storyService.fetchNextUser(this.intranet.uid, currentUserId).then(userUid => this.nextUserId = userUid);
            storyService.fetchPreviousUser(this.intranet.uid, currentUserId).then(userUid => this.prevUserId = userUid);
        },
        _keyListener(event) {
            if (event.key === "ArrowRight") {
                this.next();
            }
            if (event.key === "ArrowLeft") {
                this.previous();
            }
        },
        onStoryDeleteBtn(data) {
            this.$emit("onStoryDeleteBtn", data);
        },
        async init() {
            try {
                this.loading = true;
                await this.loadStories(this.initialUserUid);
            } catch (e) {
                console.error("error loading user stories", e);
            } finally {
                this.loading = false;
            }
            this.loadPrevAndNextUsers(this.initialUserUid);
        },
    }
});
</script>

<style lang="scss" scoped>
    .no-longer-available-container {
        background: $foreground;
        div {
            background: $background;
        }
    }

    @media(max-width: 768px) {
        .user-story-slides {
            margin: 0 -2rem;
        }
    }

    .user-story-slides {
        margin: auto;
        max-width: 50vh;
        position: relative;
        margin-top: 50vh;
        transform: translateY(-50%);
        transition: .3s ease-in-out;

        .story-button {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);

            &.prev {
                left: -3rem;
            }

            &.next {
                right: -3rem;
            }

            .icon {
                vertical-align: bottom;
            }

            @media(max-width: 768px) {
                &.prev {
                    left: 2rem;
                }

                &.next {
                    right: 2rem;
                }
            }
        }
        ::v-deep {
            .vueperslides__progress {
                position: absolute;
                top: 24px;
                left: 3rem;
                right: 3rem;
                z-index: 1;
                height: 4px;
                background: rgba($black, $opacity-twenty);
                color: $white;
                border-radius: 5px;

                > div {
                    border-radius: 5px;
                }
            }

            .vueperslides__fractions {
                position: absolute;
                right: 2rem;
                bottom: 2rem;
            }

            .reaction-graph {
                position: absolute;
                bottom: 0;
                margin-left: 1.25rem;

                .total-count {
                    font-size: 1rem;
                    margin-left: .3rem;
                }
            }

            .vueperslides__fractions,
            .reaction-graph {
                height: 2rem;
                z-index: 2;
                padding: .2em .5em;
                border: none;
                border-radius: var(--border-radius);
                color: $white;
                background: rgba($black, .2);
            }
        }
    }

</style>
