<template>
    <article class="story cover">
        <view-trigger
            :entity="story"
            :entity-type="'story'"
        />
        <div
            class="image-wrapper"
        >
            <div
                class="image depth-2"
                :class="imageClass"
                :style="`background-image: url('${storyImageUrl}')`"
            ></div>
            <icon-bubble-overlay
                :svg-content-string="reactionIcon"
                :bottom="-20"
                :top="10"
                :max-duration="2"
                :min-duration="1"
            />
            <user-loading-component :user-uid="story.creatorUid">
                <template #default="{userLoading, user}">
                    <div
                        v-fade-in.when="!userLoading"
                        class="meta gradient-top"
                    >
                        <user-link
                            :uid="story.creatorUid"
                            :v-card-on-hover="false"
                        >
                            <avatar
                                v-if="user"
                                :display-name="user.displayName"
                                :photoURL="user.photoURL"
                            />
                        </user-link>
                        <div class="info">
                            <div
                                v-if="user"
                                v-resize:longname.gt="140"
                                class="author"
                            >
                                <user-link
                                    :uid="story.creatorUid"
                                    :v-card-on-hover="false"
                                >
                                    {{ user.displayName }}
                                </user-link>
                            </div>
                            <div
                                v-if="story.creationDate"
                                class="since"
                            >
                                {{ formattedCreationDate }}
                            </div>
                        </div>
                        <div class="actions">
                            <ViewersButton
                                v-if="story.creatorUid === currentUser.uid"
                                :entity="story"
                            />
                            <div
                                v-if="story.creatorUid === currentUser.uid"
                                v-tooltip.top="$t('delete')"
                                class="control delete"
                                @click="onDeleteBtn()"
                            >
                                <Icon name="trash"/>
                            </div>
                        </div>
                    </div>
                </template>
            </user-loading-component>
        </div>
        <div
            v-fade-in.when="story.text"
            class="text"
        >
            {{ story.text }}
        </div>
        <div class="story-footer">
            <StoryFooter
                :story="story"
                @react="triggerAnimation"
            />
        </div>
    </article>
</template>

<script>
import { storyService } from "@web/services";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET } from "@/store/intranet/getters";
import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import { CURRENT_USER } from "@/store/auth/getters";
import {
    friendlyFormatDateFromDayJs,
    toDayJs,
} from "@/lib/time-utils";
import UserLink from "@/components/user/UserLink";
import UserLoadingComponent from "@/components/user/UserLoadingComponent";
import Avatar from "@/components/Avatar";
import StoryFooter from "@/views/story/StoryFooter";
import ViewTrigger from "@/components/view-tracking/ViewTrigger";
import IconBubbleOverlay from "@/components/reaction/IconBubbleOverlay";
import reactionIcons from "@web/components/reaction/reaction-icons";
import ViewersButton from "@/components/view-tracking/ViewersButton";

export default {
    name: "StoryDetail",
    components: {
        ViewersButton,
        ViewTrigger,
        Avatar,
        UserLoadingComponent,
        UserLink,
        StoryFooter,
        IconBubbleOverlay,
    },
    props: {
        story: { type: Object, require: true },
    },
    data() {
        return {
            imageClass: "",
            storyImageUrl: "",
            reactionIcon: undefined,
            showBubbles: false,
        };
    },
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
            currentUser: AUTH_MODULE_NAME + CURRENT_USER
        }),
        formattedCreationDate() {
            return friendlyFormatDateFromDayJs(toDayJs(this.story.creationDate));
        },
    },
    mounted() {
        this.fetchImage();
    },
    methods: {
        onDeleteBtn() {
            this.$emit("onStoryDeleteBtn", { story: this.story });
        },
        async fetchImage() {
            if (this.intranet && this.intranet.uid) {
                this.storyImageUrl = await storyService.fetchDownloadUrlForStoryImage(this.intranet.uid, this.story);
                await this.setImageClass();
            }
        },
        async setImageClass() {
            const image = new Image();
            image.src = this.storyImageUrl;
            const aspect = image.naturalWidth / image.naturalHeight;
            this.imageClass = (aspect < 0.85) ? "portrait" : "landscape";
        },
        triggerAnimation(reactionType) {
            if (reactionType === null || window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
                this.reactionIcon = undefined;
                return;
            }
            this.reactionIcon = reactionIcons[reactionType];
        },
    },
};
</script>

<style lang="scss" scoped>

.aui-layout .story,
:host-context(.aui-layout) .story {
    margin-bottom: 4rem;
}

.story {
    display: flex;
    animation-delay: var(--stagger);
    flex: 1 0 auto;
    margin: 1rem;
    border: none;
    height: calc(100% - 2rem);

    .image-wrapper {
        position: relative;
        z-index: 1;
        transition: transform 0.2s ease;
        flex: 0 0 auto;
        width: 100%;
        height: auto;
        border-radius: var(--border-radius);
        overflow: hidden;

        .image {
            background: no-repeat center/cover;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            overflow: hidden;

            &.landscape {
                background: no-repeat center/contain;

                &:before {
                    content: "";
                    position: absolute;
                    background: no-repeat top/cover;
                    background-image: inherit;
                    width: 100%;
                    height: 100%;
                    z-index: -5;
                    -webkit-filter: blur(50px);
                    filter: blur(50px);
                    transform: scaleX(1.5);
                }

            }
        }

        .gradient-top {
            background: linear-gradient(180deg, rgba($black, $opacity-eighty) 0%, rgba($black, 0) 100%);
            width: 100%;
            top: -1px;
            position: absolute;
        }

        .avatar {
            width: 3rem;
            height: 3rem;
            margin: 1rem;
            flex-shrink: 0;
        }

        @media(max-width: 768px) {
            .avatar {
                width: 2rem;
                height: 2rem;
            }
        }

        .meta {
            display: flex;
            padding: 1rem 0;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.4;
            letter-spacing: 0.03em;
            opacity: 1;
            color: rgba($white, 1);

            .info {
                align-items: baseline;

                .author {
                    margin-right: .5rem;
                    font-weight: bold;

                }

                .since {
                    opacity: 0.7;
                    font-size: 0.75rem;
                }
            }

            .actions {
                display: flex;
                margin-left: auto;
                color: $white;
                padding: 0.5rem;

                .control {
                    padding: 0.5rem;

                    ::v-deep {
                        .icon-svg svg path {
                            fill: white !important;
                        }
                    }
                }
            }
        }
    }

    .text {
        position: absolute;
        z-index: 1;
        color: $white;
        bottom: 12%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: .5rem;
        background-color: $black;
        border-radius: var(--border-radius);
        max-width: 80%;
        word-break: break-word;
        text-align: center;
    }

    .story-footer {
        z-index: 1;
        left: 50%;
        width: calc(100% - 2rem);
        transform: translate(-50%);
        position: absolute;
        bottom: 2rem;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
</style>
