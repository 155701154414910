<template>
    <svg
        v-if="processedContent"
        ref="animationCanvas"
        class="animation-canvas"
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs>
            <symbol
                v-if="processedContent"
                :id="elementUid"
            >
                <g v-html="processedContent"/>
            </symbol>
        </defs>

        <use
            v-for="(instance, index) in instances"
            :key="index"
            :href="`#${elementUid}`"
            class="animation-instance"
            :style="{
                '--pos-x': instance.posX,
                '--pos-y-start': instance.posYStart,
                '--pos-y-end': instance.posYEnd,
                '--scale': instance.scale,
                '--duration': instance.duration,
                '--delay': instance.delay,
                '--rotation-start': instance.rotationStart,
                '--rotation-end': instance.rotationEnd,
            }"
        />
    </svg>
</template>

<script>
import { v4 as uuidV4 } from "uuid";

function lerp(min, max, t) {
    return min + t * (max - min);
}

const parser = new DOMParser();

/**
 * A component that gets passed an inline SVG and bubbles it's contents up on the screen.
 * Fires the animation each time `svgContentString is changed`.
 */
export default {
    name: "IconBubbleOverlay",
    props: {
        /**
         * SVG content to instance as String
         * Only contents of the root node are used as `<symbol>`
         */
        svgContentString: {
            type: String,
        },
        /**
         * Number of instances
         */
        count: {
            type: Number,
            default: 50,
        },
        /**
         * Minimum scale an instance of the content needs to have
         */
        minScale: {
            type: Number,
            default: 0.5,
        },
        /**
         * Maximum allowed scale compared to original
         */
        maxScale: {
            type: Number,
            default: 2,
        },
        /**
         * Left Padding in percent
         */
        left: {
            type: Number,
            default: 0,
        },
        /**
         * Right Padding in percent
         */
        right: {
            type: Number,
            default: 0,
        },
        /**
         * Top Padding in percent
         */
        top: {
            type: Number,
            default: 0,
        },
        /**
         * Bottom Padding in percent
         */
        bottom: {
            type: Number,
            default: 0,
        },
        /**
         * Maximum duration of the whole animation in seconds
         */
        maxDuration: {
            type: Number,
            default: 2,
        },
        /**
         * Minimum duration of an instances animation
         */
        minDuration: {
            type: Number,
            default: 0.5,
        },
        /**
         * Maximum angle variation of the instances in one direction.
         * (eg. 90 for -90 to +90 degrees in tilt)
         */
        rotationVariance: {
            type: Number,
            default: 90,
        },
        /**
         * Maxmimum duration of delay to add before playing animation (substracted from duration)
         */
        delayVariance: {
            type: Number,
            default: 0.25,
        },
        /**
         * Percent to randomize the start Y-position by
         */
        yStartRandom: {
            type: Number,
            default: 10,
        },
        /**
         * Percent to randomize the end Y-position by
         */
        yEndRandom: {
            type: Number,
            default: 10,
        },
        /**
         * Prevent overlapping animations
         */
        debounce: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            instances: [],
            processedContent: undefined,
            lastTriggeredTime: undefined,
            elementUid: undefined,
        };
    },
    watch: {
        svgContentString: {
            handler: function(val) {
                this.processSVGString(val);
                this.play();
            },
        },
    },
    mounted() {
        this.processSVGString(this.svgContentString);
        this.play();
    },
    methods: {
        processSVGString(content) {
            if (!content) {
                this.processedContent = undefined;
                this.instances = [];
                this.lastTriggeredTime = undefined;
                return;
            }
            this.processedContent = parser.parseFromString(content, "application/xml").documentElement.innerHTML;
        },
        play() {
            if (!this.processedContent) return;
            if (this.debounce && new Date().getTime() - this.lastTriggeredTime < this.maxDuration * 1000) return;

            this.elementUid = uuidV4();
            const timeWithoutDelay = this.maxDuration - this.delayVariance;
            for (let i = 0; i < this.count; i++) {
                this.instances.push({
                    posX: lerp(this.left, 100 - this.right, Math.random()) + "%",
                    posYStart: 100 - lerp(this.bottom, this.bottom + this.yStartRandom, Math.random()) + "%",
                    posYEnd: lerp(this.top, this.top - this.yEndRandom, Math.random()) + "%",
                    scale: lerp(this.minScale, this.maxScale, Math.random()),
                    duration: lerp(this.minDuration, timeWithoutDelay, Math.random()) + "s",
                    delay: this.delayVariance * Math.random() + "s",
                    rotationStart: lerp(-this.rotationVariance, this.rotationVariance, Math.random()) + "deg",
                    rotationEnd: lerp(-this.rotationVariance, this.rotationVariance, Math.random()) + "deg",
                });
            }
            this.lastTriggeredTime = new Date().getTime();
        },
    },
};

</script>

<style lang="scss" scoped>
.animation-canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.animation-instance {
    opacity: 0;
    transition: transform var(--duration) ease-in-out, opacity ease-in-out calc(var(--duration) * 0.2);
    animation: fadeInOut var(--duration) ease-in-out calc(var(--duration) * 0.2);
}

@keyframes fadeInOut {
    0% {
        opacity: 1;
        transform: translate(var(--pos-x), var(--pos-y-start)) scale(var(--scale)) rotate(var(--rotation-start));
    }

    60% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(var(--pos-x), var(--pos-y-end)) scale(var(--scale)) rotate(var(--rotation-end));
    }
}
</style>

<docs>
Example with duration of 10s and 100 elements that only bubble up half the screen.
```vue
<template>
    <div style="
    width: 600px;
    height: 400px;
    background: moccasin;
    position: relative;
    display: block;
">
    <icon-bubble-overlay
        :svg-content-string="svg"
        :count="100"
        :min-duration="5"
        :max-duration="10"
        :top="50"
        :bottom="-20"
        :min-scale=".1"
        :max-scale="1"
    />
</div>
</template>
<script>
    export default {
        data() {
            return {
                svg: `<svg width="48" height="31" viewBox="0 0 48 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M37.5484 10.2338C43.2828 10.2338 47.9315 14.8824 47.9315 20.6169C47.9315 26.3513 43.2828 31 37.5484 31C23.8615 30.5281 2.82556 17.3132 4.10659 6.32323C6.26413 -3.85763 18.3328 10.2338 37.5484 10.2338Z" fill="#A567BB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.72438 0.419556L0.585449 2.00886L2.88836 3.65918C1.84591 6.06896 4.11271 10.4167 4.11271 10.4167L10.2549 2.68415C10.2549 2.68415 6.59251 1.46087 4.35844 2.30718L1.72438 0.419556Z" fill="#00C270"/>
</svg>
`
            }
        }
    }
</script>

```
</docs>
