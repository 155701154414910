<template>
    <social-happening-card
        :happening="happening"
        :variant="variant"
        :emoji="isBirthdayToday ? emoji : '🤫'"
        :button-text="$t('social_happenings_interaction_congratulate')"
        :social-notification-type="socialNotificationType"
        :accent-emoji="emoji"
        @interacted="$emit('interacted')"
    >
        <h3>{{ title }}</h3>
        <p v-html="text" ></p>
    </social-happening-card>
</template>

<script>
import SocialHappeningCard from "@/components/social-happenings/SocialHappeningCard";
import escape from "lodash/escape";
import dayjs from "dayjs";
import { SocialNotificationType } from "@backend/common/notification/activity-types";
import { SOCIAL_NOTIFICATION_TYPE_EMOJI_MAP } from "@/components/social-notifications/config";

export default {
    name: "BirthdayCard",
    components: { SocialHappeningCard },
    props: {
        /**
         * @type {SocialHappening}
         */
        happening: {
            type: Object,
            required: true,
        },
        /**
         * @values "primary", "secondary"
         */
        variant: {
            type: String,
            default: "primary",
        },
    },
    computed: {
        isBirthdayToday() {
            return this.happening.date.isSame(dayjs(), "day");
        },
        emoji() {
            return SOCIAL_NOTIFICATION_TYPE_EMOJI_MAP[SocialNotificationType.birthday];
        },
        title() {
            return this.isBirthdayToday
                ? this.$t("social_happenings_birthday_title")
                : this.$t("social_happenings_birthday_tomorrow_title");
        },
        text() {
            if (this.isBirthdayToday) {
                return this.$t(
                    "social_happenings_birthday_text", [
                        `<strong>${this.$t("today")}</strong>`,
                        `<strong>${this.$t("has_birthday", [escape(this.happening.user.displayName)])}</strong>`,
                    ],
                );
            } else {
                return this.$t(
                    "social_happenings_birthday_tomorrow_text", [
                        `<strong>${this.$t("tomorrow")}</strong>`,
                        `<strong>${this.$t("has_birthday", [escape(this.happening.user.displayName)])}</strong>`,
                    ],
                );
            }
        },
        socialNotificationType() {
            if (!this.isBirthdayToday) {
                return undefined;
            }
            return SocialNotificationType.birthday;
        }
    }
};
</script>

<style lang="scss" scoped>

</style>
