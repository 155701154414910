<template>
    <div>
        <div
            v-if="stories.length > 0"
            class="flex flex-space-between"
        >
            <h3 class="margin-none">
                {{ $t("dashboard_stories_title") }}
            </h3>
            <div>
                <a
                    class="small-text hover-underline text-high-contrast"
                    href="#"
                    @click="openHowToCreateStoriesModal"
                >
                    {{ $t("create-story-hint") }}
                </a>
            </div>
        </div>
        <story-teaser-slides
            :key="key"
            class="stories-slides"
            @onStoryClick="openDetailView"
        />
        <user-stories @delete="refresh"/>
        <how-to-create-stories-modal />
    </div>
</template>

<script>

import StoryTeaserSlides from "@web/views/story/StoryTeaserSlides.vue";
import Vue from "vue";
import UserStories from "@web/views/story/UserStories.vue";
import {
    mapActions,
    mapGetters,
} from "vuex";
import { STORY_MODULE_NAME } from "@web/store/story/story";
import { FETCH_STORIES } from "@web/store/story/actions";
import { GET_STORIES } from "@web/store/story/getters";
import HowToCreateStoriesModal from "@web/views/story/HowToCreateStoriesModal.vue";

export default Vue.extend({
    name: "StoryTeaser",
    components: {
        StoryTeaserSlides,
        UserStories,
        HowToCreateStoriesModal
    },
    data() {
        return {
            key: null
        };
    },
    computed: {
        ...mapGetters({
            stories: STORY_MODULE_NAME + GET_STORIES,
        }),
    },
    methods: {
        ...mapActions({
            fetchStories: STORY_MODULE_NAME + FETCH_STORIES,
        }),
        async openDetailView(creatorUid) {
            this.$modal.show("user-stories", {
                creatorUid: creatorUid,
            });
        },
        async refresh(value) {
            await this.fetchStories({ limit: this.visibleSlides })
                .catch((e) => {
                    // TODO: Error Handling
                    console.log(e);
                });
            this.key = value.key;
        },
        openHowToCreateStoriesModal() {
            this.$modal.show("how-to-create-stories-modal");
        },
    },
});
</script>

<style lang="scss" scoped>
    .stories-slides {
        transition: transform .3s ease-in-out;
        position: relative;

        .vueperslide--visible {
            article {
                cursor: pointer;
            }
        }

        .vueperslides {
            margin: -1rem -1rem 0;
        }
    }
</style>
