<template>
    <div
        v-resize-callback.initial="updateSize"
        class="happiness-survey"
    >
        <h4 class="mbottom-small">
            {{ question }}
        </h4>
        <LoadingWrapper
            :is-loading="isLoading || isSubmitting"
            class="happiness-loading-wrapper"
        >
            <transition
                mode="out-in"
                name="pushIn"
            >
                <div
                    v-if="!report"
                    key="happiness-selection"
                >
                    <div class="flex flex-space-between mbottom-small">
                        <HappinessSelect
                            v-for="name in happinessValues"
                            :key="name + '-selection'"
                            v-model="value"
                            :size="iconSize"
                            :name="name"
                        />
                    </div>
                    <transition
                        mode="out-in"
                        name="pushIn"
                    >
                        <div
                            v-show="secondsLeft"
                            class="small-text centered"
                        >
                            {{ $t("happiness_saving_info") }}
                            <a
                                class="happiness-undo clickable mleft-xsmall"
                                @click="cancelSubmit()"
                            >
                                {{ $t("happiness_undo", [secondsLeft]) }}
                            </a>
                        </div>
                    </transition>
                </div>
                <div
                    v-else
                    key="happiness-report"
                >
                    <div>
                        <div class="happiness-chart flex flex-space-between">
                            <div
                                v-for="name in happinessValues"
                                :key="name + '-chart-bar'"
                                class="happiness-chart-column"
                                :style="{width: widthForHappinessChartBar}"
                            >
                                <div
                                    class="happiness-chart-label small-text"
                                    :style="{bottom: heightForHappinessValue(name)}"
                                >
                                    {{ report.count[name] }}
                                </div>
                                <div
                                    class="happiness-chart-bar"
                                    :style="{height: heightForHappinessValue(name)}"
                                ></div>
                            </div>
                        </div>
                        <div class="flex flex-space-between padding-xsmall">
                            <Icon
                                v-for="name in happinessValues"
                                :key="name + '-chart-axis'"
                                :name="name"
                                :style="{width: widthForHappinessChartBar}"
                                type="happiness"
                                size="large"
                                class="zoom-small"
                            />
                        </div>
                    </div>
                    <div class="flex flex-space-between text-medium-contrast small-text">
                        <div>
                            {{ $t("happiness_submissions_average", [reportAverageHappiness, reportAverage]) }}
                        </div>
                        <div>
                            {{ $t("happiness_submissions_count", [report.submissions, pluralize(report.submissions), report.includedDays, pluralize(report.includedDays)]) }}
                            <Icon
                                v-tooltip="$t('happiness_days_until_next_vote', [userPermissionResult.daysUntilNextVote, pluralize(userPermissionResult.daysUntilNextVote)])"
                                name="info"
                                class="cursor-pointer"
                                size="small"
                                color="var(--medium-contrast)"
                            />
                        </div>
                    </div>
                </div>
            </transition>
        </LoadingWrapper>
    </div>
</template>

<script>
import Vue from "vue";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { mapGetters } from "vuex";
import { INTRANET } from "@/store/intranet/getters";
import { happinessService } from "@/services";
import HappinessSelect from "@/components/happiness/HappinessSelect";
import {
    HappinessSurveySelectionValues,
    HappinessSurveyTypes,
    happinessTooEarlySurveySubmissionError,
} from "@backend/happiness/types";
import { analytics } from "@/analytics";
import { getGlobalConfiguration } from "@/global-config";

const waitingTimeBeforeSubmit = 3;
const maxHappinessChartBarHeight = 100;
const maxIconSize = 56;
const spaceToIconRatio = 0.8;

export default Vue.extend({
    name: "HappinessSurvey",
    components: {
        HappinessSelect,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            value: null,
            valueChangeTimeoutId: null,
            report: null,
            userPermissionResult: null,
            isSubmitting: false,
            submitIntervalId: null,
            secondsLeft: null,
            iconSize: 48,
            happinessValues: [
                HappinessSurveySelectionValues.frustrated,
                HappinessSurveySelectionValues.unhappy,
                HappinessSurveySelectionValues.neutral,
                HappinessSurveySelectionValues.happy,
                HappinessSurveySelectionValues.cheerful,
            ],
        };
    },
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
        }),
        isLoading() {
            return this.userPermissionResult === null || (this.userPermissionResult.hasPermission === false && this.report === null);
        },
        question() {
            if (this.type === HappinessSurveyTypes.company) {
                return this.$t("happiness_question_company");
            }
            if (this.type === HappinessSurveyTypes.team) {
                return this.$t("happiness_question_team");
            }
            return "Unknown type";
        },
        reportAverage() {
            if (!this.report.average) {
                return "-";
            }
            return this.report.average.toString().slice(0, 4);
        },
        reportAverageHappiness() {
            const average = Math.round(this.report.average);
            if (average === 1) {
                return this.$t("happiness_selection_frustrated").toLowerCase();
            }
            if (average === 2) {
                return this.$t("happiness_selection_unhappy").toLowerCase();
            }
            if (average === 3) {
                return this.$t("happiness_selection_neutral").toLowerCase();
            }
            if (average === 4) {
                return this.$t("happiness_selection_happy").toLowerCase();
            }
            if (average === 5) {
                return this.$t("happiness_selection_cheerful").toLowerCase();
            }
            return this.$t("unknown").toLowerCase();
        },
        widthForHappinessChartBar() {
            return `${this.iconSize}px`;
        },
    },
    watch: {
        value() {
            if (this.value === null) {
                return;
            }
            if (this.submitIntervalId) {
                analytics.log(getGlobalConfiguration().analytics_event_name_happiness_changed_while_submitting);
                clearInterval(this.submitIntervalId);
            }
            this.secondsLeft = waitingTimeBeforeSubmit;
            this.submitIntervalId = setInterval(() => {
                this.secondsLeft -= 1;
                if (this.secondsLeft <= 0) {
                    clearInterval(this.submitIntervalId);
                    this.submitIntervalId = null;
                    this.submitHappinessSurvey();
                }
            }, 1000);
        }
    },
    async mounted() {
        try {
            await this.fetchUserPermission();
            if (!this.userPermissionResult.hasPermission) {
                await this.fetchReport();
            }
        } catch (e) {
            this.$notify({
                group: "app",
                type: "error",
                text: this.$t("happiness_loading_error"),
            });
        }
    },
    methods: {
        async fetchUserPermission() {
            this.userPermissionResult = await happinessService.userHasPermission(this.intranet, this.type);
        },
        cancelSubmit() {
            analytics.log(getGlobalConfiguration().analytics_event_name_happiness_undo_submission);
            clearInterval(this.submitIntervalId);
            this.secondsLeft = null;
            this.value = null;
            this.submitIntervalId = null;
        },
        async fetchReport() {
            this.report = await happinessService.getHappinessSurveyForType(this.intranet, this.type);
        },
        async submitHappinessSurvey() {
            this.isSubmitting = true;
            try {
                this.report = await happinessService.submitHappinessSurvey(this.intranet, {
                    value: this.value,
                    type: this.type,
                });
                analytics.log(getGlobalConfiguration().analytics_event_name_happiness_susubmittedbmitted);
                this.userPermissionResult = {
                    hasPermission: false,
                    daysUntilNextVote: getGlobalConfiguration().happiness_submission_period_in_days
                };
            } catch (e) {
                const days = getGlobalConfiguration().happiness_submission_period_in_days;
                if (e.response.data.type === happinessTooEarlySurveySubmissionError) {
                    this.$notify({
                        group: "app",
                        type: "error",
                        text: this.$t("happiness_too_early_resubmission", [days, this.pluralize(days)]),
                    });
                    await Promise.all([this.fetchUserPermission(), this.fetchReport()]);
                } else {
                    this.$notify({
                        group: "app",
                        type: "error",
                        text: this.$t("happiness_saving_error"),
                    });
                }
            }
            this.isSubmitting = false;
        },
        heightForHappinessValue(name) {
            const maxValue = Math.max(...Object.values(this.report.count));
            const barHeight = Math.round(this.report.count[name] / maxValue * maxHappinessChartBarHeight);
            return barHeight + "px";
        },
        updateSize() {
            const calculatedIconSize = Math.floor(this.$el.offsetWidth / this.happinessValues.length * spaceToIconRatio);
            this.iconSize = Math.min(calculatedIconSize, maxIconSize);
        },
        pluralize(n) {
            if (n > 1 || n === 0) {
                return "s";
            }
            return "";
        }
    },
});
</script>

<style lang="scss" scoped>

.happiness-chart {
    padding: 0.5rem 0.5rem 0;
    border-bottom: 1px solid black;
}

.happiness-chart-column {
    display: inline-block;
    position: relative;
    height: 110px;
}

.happiness-chart-label {
    position: absolute;
    text-align: center;
    width: 100%;
}

.happiness-chart-bar {
    background-color: var(--primary);
    width: 100%;
    position: absolute;
    bottom: 0;
}

.happiness-undo {
    font-weight: bold;
    color: var(--primary);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
</style>
